import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { collection, query, orderBy, getDocs } from 'firebase/firestore';
import { db } from './firebase';
import './NewsletterArchives.css';


import NewsletterArchivePreview from './NewsletterArchivePreview';

function NewsletterArchives() {
  const [archivedNewsletters, setArchivedNewsletters] = useState([]);

  useEffect(() => {
    const fetchArchivedNewsletters = async () => {
      try {
        const newslettersCollectionRef = collection(db, 'previousNewsletters');
        const q = query(newslettersCollectionRef, orderBy('published', 'desc'));
        const querySnapshot = await getDocs(q);
        const newsletters = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setArchivedNewsletters(newsletters);
      } catch (error) {
        console.error('Error fetching archived newsletters:', error);
      }
    };

    fetchArchivedNewsletters();
  }, []);

  return (
    <div className="newsletter-archives">
      <h1>Previous RoboRecap Newsletters</h1>
      <div className="archives-list">
        {archivedNewsletters.map((newsletter, index) => (
          <NewsletterArchivePreview key={newsletter.id} newsletter={newsletter} index={index} />
        ))}
      </div>
    </div>
  );
}

export default NewsletterArchives;
