import React, { useState, useRef, useEffect  } from 'react';
import './PremiumSubscription.css'; // Import your PremiumSubscription styles
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';


const PremiumSubscription = () => {
  const [email, setEmail] = useState('');
  const [subscribed, setSubscribed] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [subscribeSelected, setSubscribeSelected] = useState(false);
  const [monthlySubscribe, setMonthlySubscribe] = useState(false);
    const [yearlySubscribe, setYearlySubscribe] = useState(false);
  const inputRef = useRef(null);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const scrollToPremiumSubscription = (e) => {
    e.preventDefault();
    const premiumSubscriptionMainArea = document.getElementById("premium-subscription-main-area");
    premiumSubscriptionMainArea && premiumSubscriptionMainArea.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    let url = window.location.href.split("/");
    let target = url[url.length - 1].toLowerCase();
    let element = document.getElementById(target);
    element && element.scrollIntoView({ behavior: "smooth", block: "start" });
  }, []);
  const handleMonthlyCheckboxChange = () => {
      setMonthlySubscribe(!monthlySubscribe);
      if (yearlySubscribe) {
        setYearlySubscribe(false); // Uncheck the other option if changing this one
      }
    };

    const handleYearlyCheckboxChange = () => {
      setYearlySubscribe(!yearlySubscribe);
      if (monthlySubscribe) {
        setMonthlySubscribe(false); // Uncheck the other option if changing this one
      }
    };

  const handleCheckboxChange = () => {
    setSubscribeSelected(!subscribeSelected);
  };

  const handleSubscribe = async (e) => {
    e.preventDefault();

    if (!email || email.indexOf('@') === -1) {
      setErrorMessage('Please enter a valid email address.');
      return;
    }

    // TODO: Implement logic to save email to a prelaunch subscribers list
    // For example, you can use Firebase, a backend API, or a third-party service

    try {
      const response = await fetch('https://tylerkarol.pythonanywhere.com/api/create-checkout-session', {
      //const response = await fetch('http://localhost:5000/api/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          lookup_key: 'price_1OOjN4Funb7pjSY90xEma27y',  // Replace with your actual lookup key
          email: email,
        }),
      });

      if (response.ok) {
        const sessionData = await response.json();

        // Check if the checkout URL is present in the response
        if (sessionData.checkout_url) {
          // Redirect to the checkout page
          window.location.href = sessionData.checkout_url;
        } else {
          // Handle error when checkout URL is not available
          console.error('Checkout URL not found in the response');
          setErrorMessage('An error occurred. Please try again later.');
        }
      } else {
        // Handle error response
        console.error('Failed to create checkout session');
        setErrorMessage('Failed to create checkout session. Please try again later.');
      }
    } catch (error) {
      console.error('Error during fetch:', error);
      setErrorMessage('An error occurred. Please try again later.');
    }
  };


  return (
    <div className="premium-subscription-prelaunch-page">
        <div class="introduction-section">
          <div class="intro-text">
            <h1>Maximize Gains with Premium Access</h1>
            <h2>Elevate Your Investments with Premium Access</h2>
            <p>Sign up now and secure the subscription sale price for life. Get access to exclusive market insights and tailored stock updates to elevate your investment decisions.</p>
            <a href="/" onClick={scrollToPremiumSubscription} className="scroll-to-bottom-button"><button>Get Started Today ⇨</button></a>
            <div className="subscriber-container">
              <div className="subscriber-images">
                <img className="subscriber-pic-cta" src="/images/SocialMedia/Chris.jpg" alt="Investor" />
                <img className="subscriber-pic-cta" src="/images/SocialMedia/Neelie.jpeg" alt="Investor" />
                <img className="subscriber-pic-cta" src="/images/SocialMedia/Charlie.jpeg" alt="Investor" />
                <img className="subscriber-pic-cta" src="/images/SocialMedia/Colton.jpg" alt="Investor" />
              </div>
              <p>Join our growing family of smart investors for exclusive updates.</p>
            </div>
          </div>
          <div class="intro-image">
            <img src="images/SocialMedia/Premium-Hand.png" alt="Image description" />
          </div>
        </div>

        <div className="premium-membership-what-you-get-section">
          <section className="newsletter-benefits">
            <h2>What You Get with RoboRecap Premium Membership</h2>
            <div className="benefits-list">
              <div className="benefit-item">
                <img src="/images/SocialMedia/ad-free.png" alt="Ad-Free Experience" />
                <div className="benefit-description">
                  <h3>Ad-Free Experience</h3>
                  <p>Enjoy an uninterrupted, ad-free experience as we will be introducing ads soon.</p>
                </div>
              </div>
              <div className="benefit-item">
                <img src="/images/SocialMedia/user-analysis.png" alt="User-Requested Analysis" />
                <div className="benefit-description">
                  <h3>DAILY Personalized Stock Analysis</h3>
                  <p>Personalize your subscription by selecting up to five stocks for in-depth analysis sent every morning, ensuring the content delivered aligns precisely with your investment preferences and goals.</p>
                </div>
              </div>
              <div className="benefit-item">
                <img src="/images/SocialMedia/market-coverage.png" alt="Extensive Market Coverage" />
                <div className="benefit-description">
                  <h3>Subscribe to Multiple Markets</h3>
                  <p>Expand your subscription to multiple market sectors, allowing for a comprehensive and detailed analysis, catering to a broader spectrum of investment interests.</p>
                </div>
              </div>
              <div className="benefit-item">
                <img src="/images/SocialMedia/weekly-recap.png" alt="Weekly and Monthly Recap" />
                <div className="benefit-description">
                  <h3>Weekly and Monthly Recap</h3>
                  <p>Get a detailed monthly report covering the previous month's market movements, forecasts for the upcoming month, and curated analysis of two stock picks to monitor closely.</p>
                </div>
              </div>
              {/* Add more benefits in a similar structure */}
            </div>
          </section>
        </div>
        <div className="premium-subscription-main-area" id="premium-subscription-main-area">
            <div className="subscription-header">
              <h1>Pre-launch Offer</h1>
              <h2>Elevate Your Investments with Premium Access</h2>
              <h3>Sign up now to secure the subscription sale price for life.</h3>
              <p>Subscribe for exclusive market insights and tailored stock updates to elevate your investment decisions.</p>
              <p>Visualize the potential to amplify your portfolio's annual performance by at least 1% with this vital information at your fingertips</p>
            </div>
            <div className="subscription-content">
              {/* Benefits of subscribing section <h1><del>$19.99</del> <del>$4.99</del> $0.99</h1> */}
              <div className="subscription-benefits">
                <h1>RoboRecap <br /> Premium Membership</h1>
                <div class="premium-pricing stickered">
                    <div class="sticker">Limited Time Offer</div>
                    <h1>FREE 30-Day Trial</h1>
                </div>

                <ul>
                  <li>
                    <FontAwesomeIcon icon={faCircleCheck} className="green-icon" />
                    <strong>Ad-Free Experience</strong>
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCircleCheck} className="green-icon" />
                    <strong>Extensive Market Coverage</strong>
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCircleCheck} className="green-icon" />
                    <strong>Personalized Stock Analysis</strong>
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCircleCheck} className="green-icon" />
                    <strong>Weekly and Monthly Recap</strong>
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCircleCheck} className="green-icon" />
                    <strong>5 Top Stock Picks from Analysts Daily</strong>
                  </li>
                </ul>
              </div>

              {subscribed ? (
                <p className="success-message">Thank you for subscribing! We'll notify you once we launch.</p>
              ) : (
                <form className="subscription-form" onSubmit={handleSubscribe}>
                  <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={handleEmailChange}
                    ref={inputRef}
                  />
                  <button className="premium-button">Get Started Now ⇨</button>
                  {errorMessage && <p className="error-message">{errorMessage}</p>}
                </form>
              )}
            </div>
        </div>
    </div>
  );

};

export default PremiumSubscription;
