import React from 'react';
import './RedditStocks.css'; // Import the CSS file for styling

function RedditStocks() {
  return (
    <div className="reddit-stocks">
      <h1>🔥 Hottest Stocks On Reddit 🔥</h1>
      <p>
        Stay up-to-date with the latest discussions and trends in the Reddit stocks community.
        Explore trending topics, insights, and opinions shared by fellow investors.
      </p>

      <h2>What You'll Find:</h2>
      <ul>
        <li>Trending Stock Across Reddit</li>
        <li>Target Price of Stock from Real Market Analysts</li>
        <li>Valuable Information for Your Investment Strategy</li>
      </ul>

      <h2>Example Post:</h2>
      <div className="example-email">
        <div className="email-content">
          <h4>🔥 Hottest Stocks On Reddit 🔥</h4>

          <ul>
            <p>[Stock 1]:
                <li>[Mentions on Reddit:]</li>
                <li>[Upvotes on Reddit:]</li>
                <li>[Target Price:]</li>
                <li>[Rating:]</li>
            </p>
            <p>[Stock 2]:
                <li>[Mentions on Reddit:]</li>
                <li>[Upvotes on Reddit:]</li>
                <li>[Target Price:]</li>
                <li>[Rating:]</li>
            </p>
            <p>[Stock 3]:
                <li>[Mentions on Reddit:]</li>
                <li>[Upvotes on Reddit:]</li>
                <li>[Target Price:]</li>
                <li>[Rating:]</li>
            </p>
            <p>[Stock 4]:
                <li>[Mentions on Reddit:]</li>
                <li>[Upvotes on Reddit:]</li>
                <li>[Target Price:]</li>
                <li>[Rating:]</li>
            </p>
            <p>[Stock 5]:
                <li>[Mentions on Reddit:]</li>
                <li>[Upvotes on Reddit:]</li>
                <li>[Target Price:]</li>
                <li>[Rating:]</li>
            </p>
          </ul>

          <h5>Community Insights:</h5>
          <p>Provide a summary of the insights and opinions shared by the Reddit community.</p>
        </div>
      </div>

      {/* Add a placeholder for the example post picture */}
      <div className="example-email-picture">
        <img src="/images/Email_Sample_Reddit.png" alt="Sample Reddit Stock Discussion Post" />
      </div>
    </div>
  );
}

export default RedditStocks;
