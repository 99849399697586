// App.js
import React from 'react';
import ScrollToTop from './ScrollToTop'; // Import the custom ScrollToTop component
import './App.css';
import TranscriptExtractor from './TranscriptExtractor';
import Home from './Home';
import Unsubscribe from './Unsubscribe';
import Resubscribe from './Resubscribe';
import Blog from './Blog';
import Article from './Article';
import Categories from './Categories';
import About from './About';
import Contact from './Contact';
import PositiveFeedback from './PositiveFeedback';
import NegativeFeedback from './NegativeFeedback';
import StockMarket from './StockMarket';
import Housing from './Housing';
import Bonds from './Bonds';
import AnalystStockPicks from './AnalystStockPicks';
import RedditStocks from './RedditStocks';
import OverallMarketTrends from './OverallMarketTrends';
import ConfirmationPage from './ConfirmationPage';
import PremiumSubscription from './PremiumSubscription';
import PremiumSuccess from './PremiumSuccess';
import PremiumUnsubscribe from './PremiumUnsubscribe';
import ReferralSignUp from './ReferralSignUp';
import NewsletterArchives from './NewsletterArchives';
import NewsletterArchivePreview from './NewsletterArchivePreview';
import NewsletterArchive from './NewsletterArchive';
import Navbar from './Navbar';
import Footer from './Footer';// Import the Navbar component
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Navbar /> {/* Use the Navbar component */}
        <Routes>
          <Route path="/transcript-extractor" element={<TranscriptExtractor />} />
          <Route path="/" element={<Home />} />
          <Route path="/unsubscribe" element={<Unsubscribe />} />
          <Route path="/resubscribe" element={<Resubscribe />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:articleTitle" element={<Article />} />
          <Route path="/categories" element={<Categories />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/feedback-positive" element={<PositiveFeedback />} />
          <Route path="/feedback-negative" element={<NegativeFeedback />} />
          <Route path="/Stock-Market" element={<StockMarket />} />
          <Route path="/Housing-Market" element={<Housing />} />
          <Route path="/Bonds-Market" element={<Bonds />} />
          <Route path="/Reddit-Stocks" element={<RedditStocks />} />
          <Route path="/Overall-Market-Trends" element={<OverallMarketTrends />} />
          <Route path="/Analyst-Top-Stocks" element={<AnalystStockPicks />} />
          <Route path="/Newsletter-Archives" element={<NewsletterArchives />} />
          <Route path="/Newsletter-Archives/:id" element={<NewsletterArchive />} />
          <Route path="/confirmation" element={<ConfirmationPage />} />
          <Route path="/signup" element={<ReferralSignUp />} />
          <Route path="/Premium" element={<PremiumSubscription />} />
          <Route path="/PremiumSuccess" element={<PremiumSuccess />} />
          <Route path="/PremiumUnsubscribe" element={<PremiumUnsubscribe />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

