import React from 'react';
import './Bonds.css'; // Import the CSS file for styling

function Bonds() {
  return (
    <div className="bonds-market">
      <h1>Bond Market Updates</h1>
      <p>
        Stay informed about the latest developments in the bond market with our daily updates.
        Get insights, news, and analysis to make informed decisions in the world of bonds and treasuries.
      </p>

      <h2>What You'll Receive:</h2>
      <ul>
        <li>Bond Market Overview</li>
        <li>Treasury Yield Values</li>
        <li>Key Trends and Market Analysis</li>
        <li>Expert Recommendations on Bond Investments</li>
      </ul>

      <h2>Example Email:</h2>
      <div className="example-email">
        <div className="email-content">
          <h4>Summary Title of Main Topic:</h4>
          <p>Introduction: Brief introduction regarding the latest trends in the bond market.</p>

          <h5>Treasury Yield Values:</h5>
          <ul>
            <li>2-Year Treasury Yield: [Value]</li>
            <li>10-Year Treasury Yield: [Value]</li>
            <li>30-Year Treasury Yield: [Value]</li>
          </ul>

          <h5>Key Points:</h5>
          <ul>
            <li>[Key Point 1]: [Description of the first key point in the bond market.]</li>
            <li>[Key Point 2]: [Description of the second key point in the bond market.]</li>
            <li>[Key Point 3]: [Description of the third key point in the bond market.]</li>
          </ul>

          <h5>Overall Analysis:</h5>
          <p>Provide in-depth analysis of the current bond market conditions and potential future trends.</p>
        </div>
      </div>

      {/* Add a placeholder for the example email picture */}
      <div className="example-email-picture">
        <img src="/images/Email_Sample_Bonds.png" alt="Sample Bond Market Summary From Newsletter" />
      </div>
    </div>
  );
}

export default Bonds;
