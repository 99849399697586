import React, { useState } from 'react';
import './Contact.css';
import axios from 'axios';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [submitted, setSubmitted] = useState(false); // State to track form submission
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [faqItems, setFaqItems] = useState([
      {
        question: "What is RoboRecap?",
        answer: "RoboRecap is an AI-powered service that transcribes and summarizes market expert analysts opinions, providing you with concise daily reports on market insights.",
        isOpen: false, // Initially, the answer is hidden
      },
      {
        question: "How does RoboRecap work?",
        answer: "RoboRecap utilizes advanced natural language processing (NLP) and machine learning techniques to summarize content from broadcasts and other sources where analysts give their opinion.",
        isOpen: false,
      },
      {
        question: "What is the purpose of RoboRecap?",
        answer: "The primary goal of RoboRecap is to help users stay informed about financial markets and investment insights by condensing lengthy analyst discussions into brief, easy-to-read summaries.",
        isOpen: false,
      },
      {
        question: "Is RoboRecap free to use?",
        answer: "Yes, RoboRecap offers a free subscription tier with basic features. However, we are rolling out premium subscription options for more advanced functionality and content in the future.",
        isOpen: false,
      },
      {
        question: "Can I customize the content that RoboRecap summarizes?",
        answer: "Yes! You can customize which markets you want to receive updates on daily. This can be found at <a href='roborecap.com/categories'>",
        isOpen: false,
      }
      // Add more FAQ items as needed
    ]);
  const toggleAnswer = (index) => {
      const updatedFaqItems = [...faqItems];
      updatedFaqItems[index].isOpen = !updatedFaqItems[index].isOpen;
      setFaqItems(updatedFaqItems);
    };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://tylerkarol.pythonanywhere.com/api/send-contact-email', {
        name: formData.name,
        email: formData.email,
        message: formData.message,
      });

      console.log('Email sent successfully:', response.data.message);
      // Set the success message
      setSuccessMessage('Message sent successfully. Thank you!');
      // Update the state to indicate submission
      setSubmitted(true);
    } catch (error) {
      console.error('Error sending email:', error);
      // Set the error message
      setErrorMessage('Error sending email. Please try again later.');
    }
  };


  return (
    <div className="contact">
      <img className="contactImage" src="/images/Logo_no_words_black.png" alt="RoboRecap Logo" />
      <div className="contactFAQandForm">
        {submitted ? (
          <div className="confirmation-message">
            <p>{successMessage}</p>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="contactForm">
        <h1>We want to hear from you!</h1>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="textContact"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="emailContact"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
              />
            </div>
            <button type="submit">Send Message</button>
          </form>
        )}
        {errorMessage && <p>{errorMessage}</p>}
        <div className="faqDiv">
            <h1>FAQ</h1>
          {faqItems.map((item, index) => (
                  <div key={index} className="faq-item"  onClick={() => toggleAnswer(index)}>
                    <h3>
                      {item.question}
                    </h3>
                    {item.isOpen && <p>{item.answer}</p>}
                  </div>
                ))}
        </div>
      </div>
    </div>
  );
}

export default Contact;
