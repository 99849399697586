import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDw2-0ikIUHJh3FKgLxNp2RRfdTqImQMjE",
  authDomain: "robo-recap-report.firebaseapp.com",
  projectId: "robo-recap-report",
  storageBucket: "robo-recap-report.appspot.com",
  messagingSenderId: "688907113598",
  appId: "1:688907113598:web:c5e30683c1b17a6ec2742f",
  measurementId: "G-G193S9RN9B"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// Add debug logs
console.log('Firebase initialized successfully');
console.log('Firestore connection:', db);

export { db };
