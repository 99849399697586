import React, { useState, useEffect } from 'react';
import './feedback.css';
import { doc, updateDoc, FieldValue, increment, arrayUnion } from 'firebase/firestore';
import { db } from './firebase'; // Adjust the path to firebase.js as needed

function PositiveFeedback() {
  const [feedback, setFeedback] = useState([]);
  const [customMessage, setCustomMessage] = useState(''); // State for the custom message
  const [name, setName] = useState(''); // State for the user's name
  const [submitted, setSubmitted] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    // Increment the totalResponses count when the component is mounted
    const positiveFeedbackDocRef = doc(db, 'feedback', 'positive'); // Replace with your actual document path
    updateDoc(positiveFeedbackDocRef, { pageLoads: increment(1) });
  }, []);

  const handleFeedbackSelection = (selectedFeedback) => {
    // Toggle the selection of feedback options
    if (feedback.includes(selectedFeedback)) {
      setFeedback(feedback.filter((item) => item !== selectedFeedback));
    } else {
      setFeedback([...feedback, selectedFeedback]);
    }
  };

  const handleCustomMessageChange = (e) => {
    setCustomMessage(e.target.value);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle feedback submission logic based on the selected feedback and custom message
    // Customize the API endpoint, success message, and error handling

    // After a successful submission:
    setSuccessMessage(
      `Thank you for your positive feedback: "${feedback.join(', ')}"`
    );
    setSubmitted(true);

    // Increment the totalResponses count
    const positiveFeedbackDocRef = doc(db, 'feedback', 'positive'); // Replace with your actual document path
    await updateDoc(positiveFeedbackDocRef, { totalResponses: increment(1) });

    // Increment the selected feedback options
    const updateFields = {};
    feedback.forEach((option) => {
      updateFields[option] = increment(1);
    });

    // Add the custom message to your data
    updateFields['customMessage'] = customMessage;

    await updateDoc(positiveFeedbackDocRef, updateFields);

    // Store custom message in the "testimonials" document
    const testimonialsDocRef = doc(db, 'feedback', 'testimonials'); // Replace with your actual document path
    await updateDoc(testimonialsDocRef, {
      customMessages: arrayUnion({ name, message: customMessage })
    });
  };

  // Define the positive feedback options
  const positiveFeedbackOptions = [
    'It was informative and helpful',
    'I enjoyed the content',
    'It was relevant to my investments',
    'I found it insightful and valuable',
    "I'd like to receive more emails like this",
    'Something else'
  ];

  return (
    <div className="feedback">
      <h1>Thank you for your feedback.</h1>
      <h2>We value your input! Please let us know what we can improve. Click all that apply.</h2>
      {submitted ? (
        <div className="confirmation-message">
          <p>{successMessage}</p>
        </div>
      ) : (
        <>
          <div className="feedback-options">
            {positiveFeedbackOptions.map((option) => (
              <label key={option}>
                <input
                  type="checkbox"
                  value={option}
                  checked={feedback.includes(option)}
                  onChange={() => handleFeedbackSelection(option)}
                />
                {option}
              </label>
            ))}
          </div>

          {/* Custom message input field */}
          <div className="custom-testimonial">
            <h1>Tell us what you think of RoboRecap!</h1>
            <h3> ** This is optional, but we will feature you on the testimonials page! **</h3>
            {/* Name input field */}
            <div className="custom-testimonial-labels">
                <label>
                  Your Name:
                  <input
                    type="text"
                    value={name}
                    onChange={handleNameChange}
                    placeholder="Your Name"
                  />
                </label>

                <label>
                  Custom Message:
                  <textarea
                    value={customMessage}
                    onChange={handleCustomMessageChange}
                    placeholder="Write your custom message here"
                  />
                </label>
            </div>




          </div>

          <form onSubmit={handleSubmit} className="feedbackForm">
            <button type="submit">Submit Feedback</button>
          </form>
        </>
      )}
    </div>
  );
}

export default PositiveFeedback;
