import React, { useEffect, useState, useRef } from 'react';
import { getDocs, collection, query, where, getDoc, updateDoc, setDoc, doc } from "firebase/firestore"; // Import Firestore related modules
import { db } from './firebase'; // Adjust the path to firebase.js as needed
import './ReferralSignUp.css';
import axios from 'axios'; // Import axios for making HTTP requests

function ReferralSignUp() {
  const [subscriberData, setSubscriberData] = useState(null);
  const [error, setError] = useState(null);
  const [subscribed, setSubscribed] = useState(false);
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState("");
  const inputRef = useRef(null); // Create a ref for the input element
  const [errorMessageVisible, setErrorMessageVisible] = useState(false);

  const handleEmailChange = (e) => {
      setEmail(e.target.value);
    };

    const handleSubscribe = async (e) => {
      e.preventDefault(); // Prevent the default form submission behavior
          if (!email || email.indexOf('@') === -1) {
                    setErrorMessageVisible(true); // Show the error message container
                    return;
                  }
      try {
        const lowercaseEmail = email.toLowerCase();
        console.log("Lowercased email:", lowercaseEmail);

        // Get the current count of documents in the "subscribers" collection
        const subscribersCollectionRef = collection(db, "subscribers");
        console.log("Subscribers collection reference:", subscribersCollectionRef);

        const querySnapshot = await getDocs(subscribersCollectionRef);
        const currentCount = querySnapshot.docs.length;
        console.log("Current document count:", currentCount);

        // Generate the next document ID
        const nextDocumentId = `subscriber_${currentCount + 1}`;
        console.log("Next document ID:", nextDocumentId);

        // Find the subscriber document with the matching email
        let subscriberDocRef;
        querySnapshot.forEach((doc) => {
          const subscriberData = doc.data();
          console.log("Checking document with email:", subscriberData.email);
          if (subscriberData.email === lowercaseEmail) {
            subscriberDocRef = doc.ref;
            console.log("Matching document found:", subscriberData.email);
          }
        });

        if (subscriberDocRef) {
          // Subscriber with the email already exists, update subscriptions
          console.log("Subscriber with email already exists. Updating subscriptions.");

          const subscriberData = await getDoc(subscriberDocRef);
          if (!subscriberData.data().referralLink) {
            // Generate a referral link and update the subscriber document
            const referralLink = generateReferralLink();
            await updateDoc(subscriberDocRef, { referralLink: referralLink });
          }

          await updateDoc(subscriberDocRef, {
            "subscriptions.stocks": true,
            "subscriptions.housing": true,
            "subscriptions.bonds": true,
            "subscriptions.analystStockPicks": true,
            "subscriptions.overallMarketTrends": true,
            "subscriptions.redditStocks": true,
            "subscriptions.": true,
            "isSubscribed": true,
            "Premium": false
          });

          console.log("Subscriber document updated with email:", lowercaseEmail);
          setSubscribed(true);
          await sendWelcomeEmail(email);
          window.location.href = '/confirmation';
        } else {
          // Subscriber with the email does not exist, create a new document
          console.log("Subscriber with email does not exist. Creating a new document.");
          const initialSubscriptions = {
            stocks: true,
            housing: true,
            bonds: true,
            analystStockPicks: true,
            overallMarketTrends: true,
            redditStocks: true

          };

          const newSubscriberDocRef = doc(db, "subscribers", nextDocumentId);
          console.log("New subscriber document reference:", newSubscriberDocRef);

          const referralLink = generateReferralLink();
          console.log("Referral Link for user: " + referralLink);

          await setDoc(newSubscriberDocRef, {
            email: lowercaseEmail,
            isSubscribed: true,
            subscribedOn: new Date(),
            subscriptions: initialSubscriptions,
            referralLink: referralLink,
            referralCount: 0
          });

          console.log("New subscriber document created with email:", lowercaseEmail);

          const referredReferralLink = window.location.href;

          if (referredReferralLink) {
            try {
              // Find the referrer using the referral link
              const referrerQuerySnapshot = await getDocs(subscribersCollectionRef, where("referralLink", "==", referredReferralLink));

              let matchingReferrerDoc = null;

              referrerQuerySnapshot.forEach((referrerDoc) => {
                const referrerData = referrerDoc.data();
                console.log("Matching referrer data:", referrerData);

                // Check if this referrer's referralLink matches the referredReferralLink
                if (referrerData.referralLink === referredReferralLink) {
                  matchingReferrerDoc = referrerDoc;
                }
              });

              if (matchingReferrerDoc) {
                // Matching referrer found
                const referrerData = matchingReferrerDoc.data();
                console.log("Matching referrer data:", referrerData);

                const referrerRef = matchingReferrerDoc.ref;
                console.log("Referrer document reference:", referrerRef);

                const emailOfMatchingReferrer = referrerData.email;
                console.log("Email of the matching referrer:", emailOfMatchingReferrer);

                const currentReferralCount = referrerData.referralCount;
                console.log("Current referral count of the referrer:", currentReferralCount);

                const newReferralCount = currentReferralCount + 1;

                try {
                  // Update the referrer's referralCount
                  await updateDoc(referrerRef, { referralCount: newReferralCount });
                  console.log("Referrer's referralCount increased to", newReferralCount);
                } catch (updateError) {
                  console.error("Error updating the referrer's referralCount:", updateError);
                }
              } else {
                console.log("No referrer found for the given referral link.");
              }
            } catch (queryError) {
              console.error("Error querying the referrer:", queryError);
            }
          } else {
            console.log("No referral link provided.");
          }





          setSubscribed(true);
          await sendWelcomeEmail(email);
          window.location.href = '/confirmation';
        }
      } catch (error) {
        console.error("Error adding/updating subscriber:", error);
      }
    };

  useEffect(() => {
    // Get the referral link from the URL
    const urlParams = new URLSearchParams(window.location.search);
    const referralLink = window.location.href;

    if (referralLink) {
      console.log("Referral link found:", referralLink);

      // Define a reference to the "subscribers" collection
      const subscribersCollectionRef = collection(db, "subscribers");

      // Execute a query to retrieve a subscriber with the matching referral link
      const q = query(subscribersCollectionRef, where("referralLink", "==", referralLink));

      console.log("Querying Firestore for matching referral link...");

      getDocs(q)
        .then((querySnapshot) => {
          console.log("Query completed successfully.");
          if (!querySnapshot.empty) {
            console.log("Matching subscriber found.");
            querySnapshot.forEach((doc) => {
              const data = doc.data();
              console.log("Subscriber data:", data);
              setSubscriberData(data);
            });
          } else {
            console.log("No matching subscriber found.");
            setError("Invalid or expired referral link.");
          }
        })
        .catch((error) => {
          console.error("Error fetching subscriber data:", error);
          setError("An error occurred while processing your request.");
        });
    } else {
      // No referral link in the URL
      console.log("No referral link found.");
      setError("Invalid referral link.");
    }
  }, []);
  const generateReferralLink = () => {
      // Generate a unique identifier, e.g., a random string or a unique ID
      const uniqueIdentifier = generateGuid(); // Implement this function accordingly

      // Construct the referral link based on your desired format
      const referralLink = `https://roborecap.com/signup?ref=${uniqueIdentifier}`;

      return referralLink;
    };
    const generateGuid = () => {
      // Generate a random part of the GUID
      const s4 = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);

      // Combine the random parts to create a complete GUID
      return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
    };
    const sendWelcomeEmail = async (email) => {
        try {
          console.log("Sending welcome email.");
          // Make an HTTP POST request to the Flask endpoint
          //const response = await axios.post('http://localhost:5000/api/send-welcome-email', {
          const response = await axios.post('https://tylerkarol.pythonanywhere.com/api/send-welcome-email', {
            email: email
          });

          if (response.data.message === 'Welcome email sent successfully') {
            console.log("Welcome email sent successfully");
          } else {
            console.error("Failed to send welcome email");
          }
        } catch (error) {
          console.error("Error sending welcome email:", error);
        }
      };

  return (
    <div className="referral-sign-up">
      {error && <p className="error">{error}</p>}
      {subscriberData && (
        <div className="subscriber-info">
          <div className="welcome-section">
            <h4 className="welcome-header-first">Your friend knows quality when they see it, and so do you!</h4>
            <p className="welcome-text">We owe a big "thank you" to your friend for pointing you toward RoboRecap!</p>
            <p className="welcome-text-2">Welcome to <span className="roboTitleSignUp">RoboRecap - </span> Where AI Innovation Meets Expert Insights for Your Quick, Friendly Guide to Financial Wisdom and Market Updates.</p>
          </div>

          {subscribed ? (
                <p>Thank you for subscribing! Check your email for a welcome letter.</p>
              ) : (
                <form className="subscription-form" onSubmit={handleSubscribe}>
                  <input
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={handleEmailChange}
                    ref={inputRef}
                    onInvalid={(e) => {
                        e.preventDefault(); // Prevent the default browser validation message
                        setErrorMessage(e.target.validationMessage);
                    }}
                  />
                  <button>Subscribe</button>
                </form>
              )}
              {errorMessage && (
                  <div className="error-message-container">
                    <p className="error-message">{errorMessage}</p>
                  </div>
                )}


          <div className="testimonials">
            <h2 className="testimonials-header">But, don't take our word for it. Here's what our subscribers have to say:</h2>
              <p className="testimonials-text">🌟 "RoboRecap has made me a smarter investor. It's like having a financial advisor in your inbox every day." - Mark T.</p>
              <p className="testimonials-text">🌟 "I've subscribed to many newsletters, but RoboRecap's advice is gold. My portfolio thanks you!" - Lisa R.</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default ReferralSignUp;
