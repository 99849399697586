import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './ArticlePreview.css'; // Import your CSS file for styling

const ArticlePreview = ({ post }) => {
  // Extract post data
  const { ID, featured_image, title, excerpt, date, content } = post;
  const [articleId] = useState(ID);

  // This function safely converts HTML entities to their corresponding characters
  const decodeHtmlEntities = (html) => {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  };

  // Format the date to a human-readable format (e.g., "October 23, 2023")
  const formattedDate = new Date(date).toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  const modifiedTitle = title
    .replace(/[\s,]+/g, '-')  // Replace spaces and commas with hyphens
    .replace(/&#8217;/g, "'") // Replace "&#8217;" with a single apostrophe
    .replace(/[^\w-\s']+/g, '')
    .toLowerCase();           // Convert to lowercase

  return (
    <div className="article-preview">
      {featured_image && (
          <img
            className="preview-image"
            alt="article header image"
            src={featured_image}
          />
        )}
      <Link to={`/blog/${modifiedTitle}`} state={{ articleId }} className="preview-link">
        <h1 className="preview-title">{decodeHtmlEntities(title)}</h1>
        <p className="publication-date">Published on {formattedDate}</p>
        <div
            className="preview-excerpt"
            dangerouslySetInnerHTML={{ __html: decodeHtmlEntities(excerpt) }}
        />
        <Link to={`/blog/${modifiedTitle}`} state={{ articleId }}>
            <button className="read-more-btn">Read More</button>
        </Link>
      </Link>



      {/* Render the full content as HTML
      <div
        className="full-content"
        dangerouslySetInnerHTML={{ __html: decodeHtmlEntities(content) }}
      />
      */}
    </div>
  );
};

export default ArticlePreview;
