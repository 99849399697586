import React, { useState } from 'react';
import axios from 'axios'; // Import Axios
import './Unsubscribe.css'; // Import your PremiumSubscription styles

const Unsubscribe = () => {
  const [email, setEmail] = useState('');
  const [newsletterMessage, setNewsletterMessage] = useState('');
  const [premiumMessage, setPremiumMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [message, setMessage] = useState('');
  const [isUnsubscribed, setIsUnsubscribed] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setNewsletterMessage('');
    setPremiumMessage('');
  };

   const handleUnsubscribeNewsletter = async () => {
       if (!email) {
         setNewsletterMessage('Please enter your email address.');
         setMessageType('error');
         return;
       }

       if (window.confirm("Are you sure you want to unsubscribe from the free newsletter?")) {
         try {
           const response = await axios.post('https://tylerkarol.pythonanywhere.com/api/unsubscribe', { email });

           if (response.status === 200) {
             setNewsletterMessage('You have been unsubscribed successfully.');
             setMessageType('success');
             setIsUnsubscribed(true); // Set the flag to true to indicate successful unsubscription
           } else {
             setNewsletterMessage(response.data.message || 'Failed to unsubscribe. Please try again.');
             setMessageType('error');
           }
         } catch (error) {
           setNewsletterMessage('An error occurred while unsubscribing. Please try again later.');
           setMessageType('error');
         }
       }
     };

  const handleUnsubscribePremium = async () => {
    if (!email) {
      setPremiumMessage('Please enter your email address.');
      setMessageType('error');
      return;
    }

    try {
      const response = await axios.post('https://tylerkarol.pythonanywhere.com/api/create-customer-portal-session', { email });

      if (response.status === 200) {
        // Redirect the user to the Stripe Customer Portal for unsubscription
        window.location.href = response.data.portal_url;
      } else {
        setPremiumMessage(response.data.message || 'Failed to open the unsubscription portal. Please try again.');
        setMessageType('error');
      }
    } catch (error) {
      setPremiumMessage('An error occurred while opening the unsubscription portal. Please try again later.');
      setMessageType('error');
    }
  };

  return (
      <div className="unsub">
        <h1>We're Sad to see you go!</h1>
        {isUnsubscribed ? (
          <div>
            {newsletterMessage && <div className={`message ${messageType}`}>{newsletterMessage}</div>}
          </div>
        ) : (
          <div class="form1">
            <p>Enter your email address to unsubscribe.</p>
            <div className="form-group">
              <label htmlFor="email">Email Address:</label>
              <input
                type="email"
                id="email"
                placeholder="Enter your email"
                value={email}
                onChange={handleEmailChange}
                required
              />
            </div>
            <div className="btns">
                <button onClick={handleUnsubscribeNewsletter}>Unsubscribe from Free Newsletter</button>
                {newsletterMessage && <div className={`message ${messageType}`}>{newsletterMessage}</div>}
                <button onClick={handleUnsubscribePremium}>Unsubscribe from Premium</button>
                {premiumMessage && <div className={`message ${messageType}`}>{premiumMessage}</div>}
            </div>
          </div>
        )}
      </div>
    );
};

export default Unsubscribe;
