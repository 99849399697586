import React, { useState, useEffect } from 'react';
import { query, where } from 'firebase/firestore';
import './PremiumSuccess.css'; // Import the CSS
import { updateDoc, doc, getDoc, increment } from "firebase/firestore"; // Import Firebase
import { collection, getDocs, setDoc, orderBy } from "firebase/firestore";
import { db } from './firebase'; // Adjust the path to firebase.js as needed
import axios from 'axios'; // Import axios for making HTTP requests

function PremiumSuccess() {
    const [selectedOption, setSelectedOption] = useState(""); // State to store the selected option
    const [submitted, setSubmitted] = useState(false);
    const [submittedStocks, setSubmittedStocks] = useState(false);
    const [email, setEmail] = useState('');
    const [stockUpdates, setStockUpdates] = useState(["", "", "", "", ""]); // Array to hold 5 stock updates

    const handleStockChange = (index, value) => {
        const updatedStocks = [...stockUpdates];
        updatedStocks[index] = value.toUpperCase().trim();
        setStockUpdates(updatedStocks);
    };


    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const userEmail = urlParams.get('email');

        if (userEmail) {
            setEmail(userEmail);
            setTimeout(() => {
                updatePremiumStatus(userEmail);
                sendThankYouEmail(userEmail);
            }, 3000); // 3000 milliseconds = 3 seconds
        }
    }, []);

    const sendThankYouEmail = async (userEmail) => {
        try {
          console.log("Sending welcome email.");
          // Make an HTTP POST request to the Flask endpoint
          //const response = await axios.post('http://localhost:5000/api/send-thank-you-email', {
          const response = await axios.post('https://tylerkarol.pythonanywhere.com/api/send-thank-you-email', {
            email: userEmail
          });

          if (response.data.message === 'Welcome email sent successfully') {
            console.log("Welcome email sent successfully");
          } else {
            console.error("Failed to send welcome email");
          }
        } catch (error) {
          console.error("Error sending welcome email:", error);
        }
      };

    const updatePremiumStatus = async (userEmail) => {
        try {
            const subscribersRef = collection(db, 'subscribers');
            const q = query(subscribersRef, where('email', '==', userEmail));
            const querySnapshot = await getDocs(q);

            for (const doc of querySnapshot.docs) {
                try {
                    const timestamp = new Date(); // Capture the current timestamp
                    await updateDoc(doc.ref, {
                        Premium: true,
                        subscriptionTimestamp: timestamp, // Add subscription timestamp
                    });
                } catch (error) {
                    console.error('Error updating premium field:', error);
                }
            }
        } catch (error) {
            console.error('Error fetching documents:', error);
        }
    };
    const updateStocksInFirestore = async () => {
        try {
            // Get the user's document based on their email
            const urlParams = new URLSearchParams(window.location.search);
            const userEmail = urlParams.get('email');
            const subscribersRef = collection(db, 'subscribers');
            const q = query(subscribersRef, where('email', '==', userEmail));
            const querySnapshot = await getDocs(q);

            // Assume there's only one user document with a given email (you might need to adjust if multiple documents exist)
            querySnapshot.forEach(async (doc) => {
                try {
                    // Update the document with the stock updates provided
                    await updateDoc(doc.ref, {
                        stocks: stockUpdates,
                    });
                    setSubmitted(true);
                } catch (error) {
                    console.error('Error updating stocks field:', error);
                }
            });
        } catch (error) {
            console.error('Error fetching documents:', error);
        }
    };

    const handleFormSubmit = async () => {
            // Check if there's at least one stock entered
            if (stockUpdates.some((stock) => stock.trim() !== '')) {
                updateStocksInFirestore();
                setSubmittedStocks(true);
            } else {
                alert('Please enter at least one stock.');
            }
        };

    return (
        <div className="confirmation-page">
            <div className="confirmation-div">
                <h1>Thank You for Subscribing!</h1>
                <p>We promise you won't regret it.</p>
                {!submittedStocks ? (
                    <div>
                        <h4>Enter The Stocks You Would Like To Receive Updates On</h4>
                        <div className="stock-inputs">
                            {stockUpdates.map((value, index) => (
                                <input
                                    key={index}
                                    type="text"
                                    placeholder={`Stock ${index + 1}`}
                                    value={stockUpdates[index]}
                                    onChange={(e) => handleStockChange(index, e.target.value)}
                                />
                            ))}
                        </div>
                        <button className="button" onClick={handleFormSubmit}>Submit</button>
                    </div>
                ) : (
                    <h4>Stocks submitted successfully!</h4>
                )}
                <div className="buttonsOnConfirmation">
                    <a href="/blog" className="button">Visit Our Blog</a>
                    <a href="/categories" className="button">All The Markets We Cover</a>
                    <a href="/" className="button">Return to Home</a>
                </div>
            </div>
        </div>
    );
}

export default PremiumSuccess;
