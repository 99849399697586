import React from 'react';
import './Housing.css'; // Import the CSS file for styling

function Housing() {
  return (
    <div className="housing-market">
      <h1>Housing Market Updates</h1>
      <p>
        Stay informed about the latest developments in the housing market with our daily updates.
        Get insights, news, and analysis to make informed decisions in the real estate market.
      </p>

      <h2>What You'll Receive:</h2>
      <ul>
        <li>Real Estate Market Overview</li>
        <li>Key Trends and Market Analysis</li>
        <li>Expert Recommendations on Housing Investments</li>
      </ul>

      <h2>Example Email:</h2>
      <div className="example-email">
        <div className="email-content">
          <h4>Summary Title of Main Topic:</h4>
          <p>Introduction: Brief introduction regarding the latest trends in the housing market.</p>

          <h5>Key Points:</h5>
          <ul>
            <li>[Key Trend 1]: [Description of the first key trend in the housing market.]</li>
            <li>[Key Trend 2]: [Description of the second key trend in the housing market.]</li>
            <li>[Key Trend 3]: [Description of the third key trend in the housing market.]</li>
          </ul>

          <h5>Overall Analysis:</h5>
          <p>Provide in-depth analysis of the current housing market conditions and potential future trends.</p>
        </div>
      </div>

      {/* Add a placeholder for the example email picture */}
      <div className="example-email-picture">
        <img src="/images/Email_Sample_Housing.png" alt="Sample Housing Market Summary From Newsletter" />
      </div>
    </div>
  );
}

export default Housing;
