import React, { useState, useEffect } from 'react';
import './feedback.css';
import { doc, updateDoc, FieldValue, increment } from 'firebase/firestore';
import { db } from './firebase'; // Adjust the path to firebase.js as needed

function NegativeFeedback() {
  const [feedback, setFeedback] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
      // Increment the totalResponses count when the component is mounted
      const negativeFeedbackDocRef = doc(db, 'feedback', 'negative'); // Replace with your actual document path
      updateDoc(negativeFeedbackDocRef, { pageLoads: increment(1) });
    }, []);

  const handleFeedbackSelection = (selectedFeedback) => {
    // Toggle the selection of feedback options
    if (feedback.includes(selectedFeedback)) {
      setFeedback(feedback.filter((item) => item !== selectedFeedback));
    } else {
      setFeedback([...feedback, selectedFeedback]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle feedback submission logic based on the selected feedback
    // Customize the API endpoint, success message, and error handling

    // After a successful submission:
    setSuccessMessage(
      `Thank you for your feedback. We appreciate your input on the following issues: "${feedback.join(', ')}"`
    );
    setSubmitted(true);

    // Increment the totalResponses count
    const negativeFeedbackDocRef = doc(db, 'feedback', 'negative'); // Replace with your actual document path
    await updateDoc(negativeFeedbackDocRef, { totalResponses: increment(1) });

    // Increment the selected feedback options
    const updateFields = {};
    feedback.forEach((option) => {
      updateFields[option] = increment(1);
    });

    await updateDoc(negativeFeedbackDocRef, updateFields);
  };

  // Define the negative feedback options
  const negativeFeedbackOptions = [
    "It didn't feel authentic in tone",
    'The click was an accident',
    "It didn't have enough detail",
    'It had too much detail',
    'I get too many emails from RoboRecap',
    "The content wasn't relevant to my investments",
    'Something else'
  ];

  return (
    <div className="feedback">
      <h1>Thank you for your feedback.</h1>
      <h2>We want to do better! Please let us know what didn't meet your expectations. Click all that apply.</h2>
      {submitted ? (
        <div className="confirmation-message">
          <p>{successMessage}</p>
        </div>
      ) : (
        <>
          <div className="feedback-options">
            {negativeFeedbackOptions.map((option) => (
              <label key={option}>
                <input
                  type="checkbox"
                  value={option}
                  checked={feedback.includes(option)}
                  onChange={() => handleFeedbackSelection(option)}
                />
                {option}
              </label>
            ))}
          </div>
          <form onSubmit={handleSubmit} className="feedbackForm">
            <button type="submit">Submit Feedback</button>
          </form>
        </>
      )}
    </div>
  );
}

export default NegativeFeedback;
