import React, { useState } from "react";
import axios from "axios";

const Resubscribe = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleResubscribe = async () => {
    try {
      const response = await axios.post("https://tylerkarol.pythonanywhere.com/api/resubscribe", {
        email: email,
      });

      setMessage(response.data.message);
    } catch (error) {
      setMessage("Error: " + error.message);
    }
  };

  return (
    <div>
      <h1>Resubscribe to Newsletter</h1>
      <p>Enter your email address to resubscribe:</p>
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <button onClick={handleResubscribe}>Resubscribe</button>
      <p>{message}</p>
    </div>
  );
};

export default Resubscribe;
