import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './TranscriptExtractor.css'; // Import your CSS file

const DEV_API_URL = 'http://localhost:5000';
const PROD_API_URL = 'https://tylerkarol.pythonanywhere.com';

function TranscriptExtractor() {

    const environment = 'prod';
    const apiBaseUrl = environment === 'prod' ? PROD_API_URL : DEV_API_URL;

  const [stockVideoUrl, setStockVideoUrl] = useState('');
  const [housingVideoUrl, setHousingVideoUrl] = useState('');
  const [stockTranscript, setStockTranscript] = useState('');
  const [housingTranscript, setHousingTranscript] = useState('');
  const [bondMarketTranscript, setBondMarketTranscript] = useState('');
  const [stockSummary, setStockSummary] = useState('teststck');
  const [housingSummary, setHousingSummary] = useState('housing');
  const [bondSummary, setBondSummary] = useState('bond');
  const [introduction, setIntroduction] = useState('intro');
  const [topStocksSelection, setTopStocksSelection] = useState('TopStocksSelection');
  const [analystStockPicks, setAnalystStockPicks] = useState('');
  const [stockTrendsAndNewsInput, setStockTrendsAndNewsInput] = useState('');
  const [stockTrendsAndNews, setStockTrendsAndNews] = useState('stockTrendsAndNews');
  const [isLoading, setIsLoading] = useState(false);
  const [subject_line, setSubject_Line] = useState('');
  const [adContent, setAdContent] = useState('');

  const [emailSubject, setEmailSubject] = useState(''); // Add state for email subject
  const [emailMessage, setEmailMessage] = useState(''); // Add state for email message
  const [emailRecipients, setEmailRecipients] = useState(''); // Add state for email recipients

  const [editedIntroduction, setEditedIntroduction] = useState(introduction);
  const [editedTopStocksSelection, setEditedTopStocksSelection] = useState(topStocksSelection);
  const [editedAnalystStockPicks, setEditedAnalystStockPicks] = useState(analystStockPicks);
  const [editedStockTrendsAndNews, setEditedStockTrendsAndNews] = useState(stockTrendsAndNews);
  const [editedStockSummary, setEditedStockSummary] = useState(stockSummary);
  const [editedHousingSummary, setEditedHousingSummary] = useState(housingSummary);
  const [editedBondSummary, setEditedBondSummary] = useState(bondSummary);
  const [editedSubjectLine, setEditedSubjectLine] = useState(subject_line);
  const [editedAdContent, setEditedAdContent] = useState(adContent);


  const [isEmailApproved, setIsEmailApproved] = useState(false); // Track email approval status
  const [isEmailPreviewVisible, setIsEmailPreviewVisible] = useState(false); // Track email preview visibility

  const [formattedTranscripts, setFormattedTranscripts] = useState([]);

  useEffect(() => {
      setEditedIntroduction(introduction);
      setEditedTopStocksSelection(topStocksSelection);
      setEditedStockSummary(stockSummary);
      setEditedHousingSummary(housingSummary);
      setEditedBondSummary(bondSummary);
      setEditedAnalystStockPicks(analystStockPicks);
      setEditedStockTrendsAndNews(stockTrendsAndNews);
      setEditedSubjectLine(subject_line);
      setEditedAdContent(adContent);
    }, [introduction, adContent, analystStockPicks, stockTrendsAndNews, stockSummary, topStocksSelection, housingSummary, bondSummary, subject_line]);

  const constructPreviewEmailContent = () => {
    // Combine the edited introduction, stock, housing, and bond summaries
    const content = `${introduction}\n\n${adContent}\n\n${analystStockPicks}\n\n${stockTrendsAndNews}\n\n${stockSummary}\n\n${topStocksSelection}\n\n${housingSummary}\n\n${bondSummary}`;
    return content;
  };

  const handleStockVideoUrlChange = (e) => {
    setStockVideoUrl(e.target.value);
  };
  const handleCramerTranscriptChange = (e) => {
      setStockTranscript(e.target.value);
    };
  const handleStockTrendsAndNewsInputChange = (e) => {
    setStockTrendsAndNewsInput(e.target.value);
  };

  const handleAnalystStockPicks = (e) => {
    setAnalystStockPicks(e.target.value);
  };
  const handleSubject_LineChange = (e) => {
      setSubject_Line(e.target.value);
      console.log("subject line change - " + subject_line);
    };
  const handleAdContentChange = (e) => {
    setAdContent(e.target.value);
  }


  const handleHousingVideoUrlChange = (e) => {
    setHousingVideoUrl(e.target.value);
  };
  const handleBondMarketTranscriptChange = (e) => {
    setBondMarketTranscript(e.target.value);
  };
  const handleEmailSubjectChange = (e) => {
    setEmailSubject(e.target.value);
  };

  const handleEmailMessageChange = (e) => {
    setEmailMessage(e.target.value);
  };
  const handleSendCustomEmail = () => {
    setIsLoading(true);

    // Send a custom email with subject, message, and recipients
    axios
      .post(`${apiBaseUrl}/api/sendCustomEmail`, {
        subject: emailSubject,
        message: emailMessage,
        recipients: emailRecipients.split(',') // Split the recipients by comma if multiple emails are provided
      })
      .then((response) => {
        console.log('Custom email sent successfully:', response.data.message);
        // Handle success as needed
      })
      .catch((error) => {
        console.error('Error sending custom email:', error);
        // Handle error as needed
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleExtractTranscript = () => {
      setIsLoading(true);
      console.log("environment = ", apiBaseUrl);
      console.log('${apiBaseUrl}/api/extract-transcript');
      if (stockVideoUrl) {
        console.log("using stock video");
        axios
          .post(`${apiBaseUrl}/api/extract-transcript`, { videoUrl: stockVideoUrl })
          .then((response) => {
            console.log("2 stock", response.data);
            // Use the first available language transcript
            const transcript = response.data.transcript;
            const firstLanguageKey = Object.keys(transcript)[0];
            const firstLanguageTranscript = transcript[firstLanguageKey];
            const stockFormattedTranscript1 = formatTranscript(firstLanguageTranscript);
            console.log("stock formatted transcript before removing intro/conclusion - ", stockFormattedTranscript1);
            const stockFormattedTranscript = extractTextBetweenPatterns(stockFormattedTranscript1);
            console.log("stock formatted transcript - ", stockFormattedTranscript);
            setStockTranscript(stockFormattedTranscript);

            axios
              .post(`${apiBaseUrl}/api/extract-transcript`, { videoUrl: housingVideoUrl })
              .then((response) => {
                console.log("2 housing", response.data);
                // Use the first available language transcript
                const transcript = response.data.transcript;
                const firstLanguageKey = Object.keys(transcript)[0];
                const firstLanguageTranscript = transcript[firstLanguageKey];
                const housingFormattedTranscript = formatTranscript(firstLanguageTranscript);
                console.log("housing formatted transcript - ", housingFormattedTranscript);
                setHousingTranscript(housingFormattedTranscript);

                axios
                  .post(`${apiBaseUrl}/api/generateStockAndHousingSummaries`, {
                    stock_transcript: stockFormattedTranscript,
                    housing_transcript: housingFormattedTranscript,
                    bond_market_transcript: bondMarketTranscript,
                    analyst_stock_picks: analystStockPicks,
                    stock_trends_and_news_input : stockTrendsAndNewsInput,
                    top_stocks_selection : topStocksSelection,
                    subject_line : subject_line,
                    adContent : adContent
                  })
                  .then((summaryResponse) => {
                    setStockSummary(summaryResponse.data.stock_summary);
                    setHousingSummary(summaryResponse.data.housing_summary);
                    setBondSummary(summaryResponse.data.bond_summary);
                    setIntroduction(summaryResponse.data.introduction);
                    setTopStocksSelection(summaryResponse.data.top_stocks_selection);
                    setAnalystStockPicks(summaryResponse.data.analyst_stock_picks);
                    setStockTrendsAndNews(summaryResponse.data.stock_trends_and_news);
                    setSubject_Line(summaryResponse.data.subject_line);
                    setAdContent(summaryResponse.data.adContent);
                  })
                  .catch((error) => {
                    console.error('Error summarizing transcript:', error);
                    setStockSummary('Error summarizing stock transcript. Please try again.');
                    setHousingSummary('Error summarizing housing transcript. Please try again.');
                  })
                  .finally(() => {
                    setIsLoading(false);
                    setIsEmailPreviewVisible(true); // Show email preview after extraction and summarization
                  });
              })
              .catch((error) => {
                console.error('Error fetching housing transcript:', error);
                setHousingTranscript('Error fetching housing transcript. Please try again.');
                setIsLoading(false);
              });
          })
          .catch((error) => {
            console.error('Error fetching stock transcript:', error);
            setStockTranscript('Error fetching stock transcript. Please try again.');
            setIsLoading(false);
          });
      } else {
        console.log("no stock video, continuing");
            axios
            .post(`${apiBaseUrl}/api/extract-transcript`, { videoUrl: housingVideoUrl })
            .then((response) => {
              console.log("2 housing", response.data);
              // Use the first available language transcript
              const transcript = response.data.transcript;
              const firstLanguageKey = Object.keys(transcript)[0];
              const firstLanguageTranscript = transcript[firstLanguageKey];
              const housingFormattedTranscript = formatTranscript(firstLanguageTranscript);
              console.log("housing formatted transcript - ", housingFormattedTranscript);
              setHousingTranscript(housingFormattedTranscript);

              axios
                .post(`${apiBaseUrl}/api/generateStockAndHousingSummaries`, {
                  housing_transcript: housingFormattedTranscript,
                  bond_market_transcript: bondMarketTranscript,
                  analyst_stock_picks: analystStockPicks,
                  stock_trends_and_news_input : stockTrendsAndNewsInput,
                  top_stocks_selection: topStocksSelection,
                  subject_line: subject_line,
                  adContent : adContent,
                  stock_transcript: stockTranscript
                })
                .then((summaryResponse) => {
                  setStockSummary(summaryResponse.data.stock_summary);
                  setHousingSummary(summaryResponse.data.housing_summary);
                  setBondSummary(summaryResponse.data.bond_summary);
                  setIntroduction(summaryResponse.data.introduction);
                  setTopStocksSelection(summaryResponse.data.top_stocks_selection);
                  setAnalystStockPicks(summaryResponse.data.analyst_stock_picks);
                  setStockTrendsAndNews(summaryResponse.data.stock_trends_and_news);
                  setSubject_Line(summaryResponse.data.subject_line);
                  setAdContent(summaryResponse.data.adContent);
                })
                .catch((error) => {
                  console.error('Error summarizing transcript:', error);
                  setStockSummary('Error summarizing stock transcript. Please try again.');
                  setHousingSummary('Error summarizing housing transcript. Please try again.');
                })
                .finally(() => {
                  setIsLoading(false);
                  setIsEmailPreviewVisible(true); // Show email preview after extraction and summarization
                });
            })
            .catch((error) => {
              console.error('Error fetching housing transcript:', error);
              setHousingTranscript('Error fetching housing transcript. Please try again.');
              setIsLoading(false);
            });
      }



    };



  const handleSummarizeStock = () => {
      setIsLoading(true);
      console.log("transcript in TE: " + analystStockPicks);
      axios
        .post(`${apiBaseUrl}/api/summarizeStock`, { transcript: analystStockPicks })
        .then((summaryResponse) => {
          setStockSummary(summaryResponse.data.stock_summary);
        })
        .catch((error) => {
          console.error('Error summarizing stock transcript:', error);
          setStockSummary('Error summarizing stock transcript. Please try again.');
        })
        .finally(() => {
          setIsLoading(false);
          setIsEmailPreviewVisible(true); // Show email preview after summarization
        });
    };

  const handleApproveEmail = () => {
    setIsEmailApproved(true); // Mark email as approved
    setIsEmailPreviewVisible(false); // Hide email preview
    sendEmail(); // Call the API to send the approved email
  };

  const handleDisapproveEmail = () => {
    setIsEmailApproved(false); // Mark email as disapproved
    setIsEmailPreviewVisible(false); // Hide email preview
  };

  const sendEmail = () => {
    // Call the API to send the approved email
    axios
      .post(`${apiBaseUrl}/api/sendApprovedEmail`, {
        stock_summary: editedStockSummary,
        housing_summary: editedHousingSummary,
        bond_summary: editedBondSummary,
        introduction: editedIntroduction,
        top_stocks_selection: editedTopStocksSelection,
        analyst_stock_picks: editedAnalystStockPicks,
        stock_trends_and_news: editedStockTrendsAndNews,
        subject_line: editedSubjectLine,
        adContent: editedAdContent
      })
      .then((response) => {
        console.log('Email sent successfully:', response.data.message);
        // Handle success as needed
      })
      .catch((error) => {
        console.error('Error sending email:', error);
        // Handle error as needed
      });
  };
  const handleSendPreviewEmail = () => {
    setIsLoading(true);

    // Get the content from the edited summaries and introduction
    const content = constructPreviewEmailContent();

    // Make a POST request to the sendPreviewEmail API
    axios
      .post(`${apiBaseUrl}/api/sendPreviewEmail`, {
        stock_summary: editedStockSummary,
        housing_summary: editedHousingSummary,
        bond_summary: editedBondSummary,
        introduction: editedIntroduction,
        top_stocks_selection: editedTopStocksSelection,
        analyst_stock_picks: editedAnalystStockPicks,
        stock_trends_and_news: editedStockTrendsAndNews,
        subject_line: editedSubjectLine,
        adContent: editedAdContent
      })
      .then((response) => {
        console.log('Preview email sent successfully:', response.data.message);
        // Handle success as needed
      })
      .catch((error) => {
        console.error('Error sending preview email:', error);
        // Handle error as needed
      })
      .finally(() => {
        setIsLoading(false);
      });
  };



  const formatTranscript = (transcriptObject) => {
      const lines = transcriptObject || [];
      let formattedTranscript = '';
      const timestampRegex = /\d+:\d+:\d+\.\d+/;

      lines.forEach((line) => {
        if (typeof line === 'string' && !line.match(timestampRegex)) {
          // Remove unnecessary characters such as '>>>'
          line = line.replace(/&gt;/g, ''); // Remove '>>>'

          if (!line.startsWith('Language: en')) {
            formattedTranscript += line + ' ';
          }
        }
      });

      return formattedTranscript.trim(); // Trim any extra whitespace at the beginning or end
    };
    function extractTextBetweenPatterns(inputText) {
      const startPattern = /HEY, I'M CRAMER/;
      const endPattern = /SO STAY WITH CRAMER/;

      const startIndex = inputText.search(startPattern);
      const endIndex = inputText.search(endPattern);

      if (startIndex >= 0 && endIndex >= 0 && startIndex < endIndex) {
        const result = inputText.substring(startIndex, endIndex + endPattern.source.length);
        return result;
      } else {
        return inputText;
      }
    }

  return (
    <div className="containerTX">
      <h1>RoboRecap Admin Console</h1>
      <div className="input-group">
        <textarea
          type="text"
          placeholder="Enter Ad Content"
          value={adContent}
          onChange={handleAdContentChange}
        />
        <input
          type="text"
          placeholder="Enter Stock Video URL"
          value={stockVideoUrl}
          onChange={handleStockVideoUrlChange}
        />
        <input
          type="text"
          placeholder="Enter cramer transcript"
          value={stockTranscript}
          onChange={handleCramerTranscriptChange}
        />
        <textarea
          type="text"
          placeholder="Enter Stock Picks Commentary" // Update placeholder
          value={analystStockPicks} // Update value and onChange
          onChange={handleAnalystStockPicks}
        />
        <textarea
          type="text"
          placeholder="Enter Stock Trends Commentary" // Update placeholder
          value={stockTrendsAndNewsInput} // Update value and onChange
          onChange={handleStockTrendsAndNewsInputChange}
        />
        <input
          type="text"
          placeholder="Enter Housing Video URL"
          value={housingVideoUrl}
          onChange={handleHousingVideoUrlChange}
        />
        <textarea
          type="text"
          placeholder="Enter Bond Market Transcript"
          value={bondMarketTranscript}
          onChange={handleBondMarketTranscriptChange}
        />
        <textarea
          type="text"
          placeholder="Enter Subject Line"
          value={subject_line}
          onChange={handleSubject_LineChange}
        />
      </div>

      <div className="button-group">
        <button onClick={handleExtractTranscript} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Extract and Summarize'}
        </button>
        <button onClick={handleSummarizeStock} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Only Summarize Stock Picks'}
        </button>
      </div>

      {isEmailPreviewVisible ? (
        <div className="email-preview">
          <div
            contentEditable={true}
            dangerouslySetInnerHTML={{ __html: editedSubjectLine }}
            onInput={(e) => {
              e.preventDefault();
              setEditedSubjectLine(e.currentTarget.innerHTML);
            }}
          />
          <div
            contentEditable={true}
            dangerouslySetInnerHTML={{ __html: editedIntroduction }}
            onInput={(e) => {
              e.preventDefault();
              setEditedIntroduction(e.currentTarget.innerHTML);
            }}
          />
          <div
            contentEditable={true}
            dangerouslySetInnerHTML={{ __html: editedAdContent }}
            onInput={(e) => {
              e.preventDefault();
              setEditedAdContent(e.currentTarget.innerHTML);
            }}
          />
          <div
            contentEditable={true}
            dangerouslySetInnerHTML={{ __html: editedAnalystStockPicks }}
            onInput={(e) => {
              e.preventDefault();
              setEditedAnalystStockPicks(e.currentTarget.innerHTML);
            }}
          />
          <div
            contentEditable={true}
            dangerouslySetInnerHTML={{ __html: editedStockTrendsAndNews }}
            onInput={(e) => {
              e.preventDefault();
              setEditedStockTrendsAndNews(e.currentTarget.innerHTML);
            }}
          />
          <div
            contentEditable={true}
            dangerouslySetInnerHTML={{ __html: editedTopStocksSelection }}
            onInput={(e) => {
              setEditedTopStocksSelection(e.currentTarget.innerHTML);
            }}
          />
          <div
            contentEditable={true}
            dangerouslySetInnerHTML={{ __html: editedStockSummary }}
            onInput={(e) => setEditedStockSummary(e.currentTarget.innerHTML)}
          />
          <div
            contentEditable={true}
            dangerouslySetInnerHTML={{ __html: editedHousingSummary }}
            onInput={(e) => setEditedHousingSummary(e.currentTarget.innerHTML)}
          />
          <div
            contentEditable={true}
            dangerouslySetInnerHTML={{ __html: editedBondSummary }}
            onInput={(e) => setEditedBondSummary(e.currentTarget.innerHTML)}
          />

          {isEmailPreviewVisible && (
            <>
                <div className="button-group">
                  <button onClick={handleApproveEmail}>Approve Email</button>
                  <button onClick={handleDisapproveEmail}>Disapprove Email</button>
                  <button onClick={handleSendPreviewEmail} disabled={isLoading}>
                    {isLoading ? 'Sending Preview Email...' : 'Send Preview Email'}
                  </button>
                </div>
            </>
          )}

        </div>
      ) : isEmailApproved ? (
        <p>Email approved and sent successfully!</p>
      ) : (
        <div className="custom-email">
          <input
            type="text"
            placeholder="Email Subject"
            value={emailSubject}
            onChange={handleEmailSubjectChange}
          />
          <textarea
            placeholder="Email Message"
            value={emailMessage}
            onChange={handleEmailMessageChange}
          />
          <input
            type="text"
            placeholder="Recipients (comma-separated)"
            value={emailRecipients}
            onChange={(e) => setEmailRecipients(e.target.value)}
          />
          <button onClick={handleSendCustomEmail} disabled={isLoading}>
            {isLoading ? 'Sending Email...' : 'Send Custom Email'}
          </button>
        </div>
      )}
    </div>
  );

}

export default TranscriptExtractor;
