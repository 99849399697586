import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import './Categories.css'; // Import the CSS file
import { collection, doc, setDoc, getDocs, updateDoc, getDoc } from 'firebase/firestore';
import { db } from './firebase'; // Import your Firebase configuration here
import axios from 'axios'; // Import axios for making HTTP requests

function Categories() {

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // Define the isModalOpen state variable
  const [errorMessage, setErrorMessage] = useState("");
    const inputRef = useRef(null); // Create a ref for the input element
    const [errorMessageVisible, setErrorMessageVisible] = useState(false);

  // Function to open the modal for a specific category
  const openModal = (category) => {
    setSelectedCategory(category);
    setIsModalOpen(true); // Set isModalOpen to true when opening the modal
  };

  // Function to close the modal
  const closeModal = () => {
    setSelectedCategory(null);
    setIsModalOpen(false); // Set isModalOpen to false when closing the modal
  };

  const [email, setEmail] = useState('');
  const [selectedCategories, setSelectedCategories] = useState({
    stocks: false,
    housing: false,
    bonds: false,
    analystStockPicks: false,
    redditStocks: false,
    overallMarketTrends: false
    // Add more categories here as needed
  });
  const [subscribed, setSubscribed] = useState(false);

  const toggleCategorySelection = (category) => {
    setSelectedCategories({
      ...selectedCategories,
      [category]: !selectedCategories[category],
    });
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubscribe = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    if (!email || email.indexOf('@') === -1) {
      setErrorMessageVisible(true); // Show the error message container
      return;
    }

    try {
      const lowercaseEmail = email.toLowerCase();
      console.log("Lowercased email:", lowercaseEmail);

      // Find the subscriber document with the matching email
      let subscriberDocRef;
      const subscribersCollectionRef = collection(db, "subscribers");
      console.log("Subscribers collection reference:", subscribersCollectionRef);

      // Query for the subscriber with the given email
      const querySnapshot = await getDocs(subscribersCollectionRef);
      querySnapshot.forEach((doc) => {
        const subscriberData = doc.data();
        console.log("Checking document with email:", subscriberData.email);
        if (subscriberData.email === lowercaseEmail) {
          subscriberDocRef = doc.ref;
          console.log("Matching document found:", subscriberData.email);
        }
      });

      if (subscriberDocRef) {
        // Subscriber with the email already exists, update subscriptions
        console.log("Subscriber with email already exists. Updating subscriptions.");

        const subscriberData = await getDoc(subscriberDocRef);
        if (!subscriberData.data().referralLink) {
          // Generate a referral link and update the subscriber document
          const referralLink = generateReferralLink();
          await updateDoc(subscriberDocRef, { referralLink: referralLink });
        }

        const updatedSubscriptions = {
          stocks: selectedCategories.stocks,
          housing: selectedCategories.housing,
          bonds: selectedCategories.bonds,
          analystStockPicks: selectedCategories.analystStockPicks,
          overallMarketTrends: selectedCategories.overallMarketTrends,
          redditStocks: selectedCategories.redditStocks
          // Add more categories here as needed
        };

        await updateDoc(subscriberDocRef, {
          subscriptions: updatedSubscriptions,
          "isSubscribed": true
        });

        console.log("Subscriber document updated with email:", lowercaseEmail);
        setSubscribed(true);
        await sendWelcomeEmail(email);
        window.location.href = '/confirmation';
      } else {
        // Subscriber with the email does not exist, create a new document
        console.log("Subscriber with email does not exist. Creating a new document.");
        const initialSubscriptions = {
          stocks: selectedCategories.stocks,
          housing: selectedCategories.housing,
          bonds: selectedCategories.bonds,
          analystStockPicks: selectedCategories.analystStockPicks,
          overallMarketTrends: selectedCategories.overallMarketTrends,
          redditStocks: selectedCategories.redditStocks
          // Add more categories here as needed
        };

        // Move this part outside of the `try` block
        const querySnapshot = await getDocs(subscribersCollectionRef);
        const currentCount = querySnapshot.docs.length;
        const nextDocumentId = `subscriber_${currentCount + 1}`;
        const newSubscriberDocRef = doc(db, "subscribers", nextDocumentId);
        console.log("New subscriber document reference:", newSubscriberDocRef);



        const referralLink = generateReferralLink();
        console.log("Referral Link for user: " + referralLink);

        await setDoc(newSubscriberDocRef, {
          email: lowercaseEmail,
          isSubscribed: true,
          subscribedOn: new Date(),
          subscriptions: initialSubscriptions,
          referralLink: referralLink,
          referralCount: 0
        });

        console.log("New subscriber document created with email:", lowercaseEmail);
        setSubscribed(true);
        await sendWelcomeEmail(email);
        window.location.href = '/confirmation';
      }
    } catch (error) {
      console.error("Error adding/updating subscriber:", error);
    }
  };



  const sendWelcomeEmail = async (email) => {
      try {
        console.log("Sending welcome email.");
        // Make an HTTP POST request to the Flask endpoint
        //const response = await axios.post('http://localhost:5000/api/send-welcome-email', {
        const response = await axios.post('https://tylerkarol.pythonanywhere.com/api/send-welcome-email', {
          email: email
        });

        if (response.data.message === 'Welcome email sent successfully') {
          console.log("Welcome email sent successfully");
        } else {
          console.error("Failed to send welcome email");
        }
      } catch (error) {
        console.error("Error sending welcome email:", error);
      }
    };
  const generateReferralLink = () => {
      // Generate a unique identifier, e.g., a random string or a unique ID
      const uniqueIdentifier = generateGuid(); // Implement this function accordingly

      // Construct the referral link based on your desired format
      const referralLink = `https://roborecap.com/signup?ref=${uniqueIdentifier}`;

      return referralLink;
    };
    const generateGuid = () => {
      // Generate a random part of the GUID
      const s4 = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);

      // Combine the random parts to create a complete GUID
      return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
    };


  return (
    <div className="categories">
      <div className="subscription-explanation">
        <h1>Personalize Your RoboRecap From Our Selection Of Covered Markets</h1>
        <p>
          Subscribe to as many or as few categories as you'd like. You will only receive updates
          for the categories you are subscribed to, ensuring you get the content that matters most to you.
        </p>
      </div>
      {/* New section for market subscriptions */}
          <div className="market-subscriptions">
            <h2>Which markets would you like in your free newsletter?</h2>
            <div className="market-labels-div">
                <label className="market-label">
                  Stock Picks from Analysts
                  <input
                    type="checkbox"
                    checked={selectedCategories.analystStockPicks}
                    onChange={() => toggleCategorySelection('analystStockPicks')}
                    className="market-checkbox"
                  />
                </label>
                <label className="market-label">
                  Overall Stock Market Trends & News
                  <input
                    type="checkbox"
                    checked={selectedCategories.overallMarketTrends}
                    onChange={() => toggleCategorySelection('overallMarketTrends')}
                    className="market-checkbox"
                  />
                </label>
                <label className="market-label">
                  Reddit Top Stocks
                  <input
                    type="checkbox"
                    checked={selectedCategories.redditStocks}
                    onChange={() => toggleCategorySelection('redditStocks')}
                    className="market-checkbox"
                  />
                </label>
                <label className="market-label">
                  Jim Cramer Report
                  <input
                    type="checkbox"
                    checked={selectedCategories.stocks}
                    onChange={() => toggleCategorySelection('stocks')}
                    className="market-checkbox"
                  />
                </label>
                <label className="market-label">
                  Housing Market
                  <input
                    type="checkbox"
                    checked={selectedCategories.housing}
                    onChange={() => toggleCategorySelection('housing')}
                    className="market-checkbox"
                  />
                </label>
                <label className="market-label">
                  Bonds Market
                  <input
                    type="checkbox"
                    checked={selectedCategories.bonds}
                    onChange={() => toggleCategorySelection('bonds')}
                    className="market-checkbox"
                  />
                </label>
            </div>
            {subscribed ? (
                <p>Thank you for subscribing! Check your email for a welcome letter.</p>
              ) : (
                <form className="subscription-form"  onSubmit={handleSubscribe}>
                  <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={handleEmailChange}
                    ref={inputRef}
                    onInvalid={(e) => {
                        e.preventDefault(); // Prevent the default browser validation message
                        setErrorMessage(e.target.validationMessage);
                    }}
                  />
                  <button className="subscribe-button">
                    {subscribed ? 'Subscribed' : 'Subscribe'}
                  </button>
                </form>
            )}
            {errorMessage && (
                              <div className="error-message-container">
                                <p className="error-message">{errorMessage}</p>
                              </div>
                            )}
          </div>
      <h1 className="categoriesTitle">RoboRecap Categories</h1>

      <div className="category">
        <div className="category-content">
            <a className="categoryLink" href="/Analyst-Top-Stocks" /* Add the link to your homepage here */>
              <h2>Stock Picks from Analysts</h2>
              <p>
                Get 3 daily stock picks with 1-2 sentence explanation.
              </p>
              <p>
                This data is pulled and summarized from ALL analysts across the internet within the past 24 hrs.
              </p>
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  className="larger-checkbox"
                  checked={selectedCategories.analystStockPicks}
                  onChange={() => toggleCategorySelection('analystStockPicks')}
                />
                <span className="checkbox-text">Subscribe</span>
              </label>
            </a>
        </div>
        <div className="category-image">
          <img src="/images/Email_Sample_Analyst_Stocks.png" alt="Sample Analyst Stocks Summary From Newsletter" onClick={() => openModal('analystStockPicks')} />
        </div>
      </div>
      {selectedCategory === 'analystStockPicks' && isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal">
            {/* Display the modal content for the selected category */}
            <img
              src="/images/Email_Sample_Analyst_Stocks.png"
              alt="Sample Analyst Stocks Summary From Newsletter"
              className="modal-image"
            />
          </div>
        </div>
      )}
      <div className="category">
        <div className="category-content">
            <a className="categoryLink" href="/Overall-Market-Trends" /* Add the link to your homepage here */>
              <h2>Overall Stock Market Trends & News</h2>
              <p>
                Receive 5 key points regarding the current state of the market as well as any news that could be effecting the market.
              </p>
              <p>
                This data is pulled and summarized from ALL analysts across the internet within the past 24 hrs.
              </p>
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  className="larger-checkbox"
                  checked={selectedCategories.overallMarketTrends}
                  onChange={() => toggleCategorySelection('overallMarketTrends')}
                />
                <span className="checkbox-text">Subscribe</span>
              </label>
            </a>
        </div>
        <div className="category-image">
          <img src="/images/Email_Sample_Overall_Market.png" alt="Sample Overall Market Summary From Newsletter" onClick={() => openModal('overallMarket')} />
        </div>
      </div>
      {selectedCategory === 'overallMarket' && isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal">
            {/* Display the modal content for the selected category */}
            <img
              src="/images/Email_Sample_Overall_Market.png"
              alt="Sample Overall Market` Summary From Newsletter"
              className="modal-image"
            />
          </div>
        </div>
      )}
      <div className="category">
        <div className="category-content">
            <a className="categoryLink" href="/Reddit-Stocks" /* Add the link to your homepage here */>
              <h2>Reddit Top Stocks</h2>
              <p>
                Get the latest scoop on the next meme stock that's going to the moon 🚀🚀🚀
              </p>
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  className="larger-checkbox"
                  checked={selectedCategories.redditStocks}
                  onChange={() => toggleCategorySelection('redditStocks')}
                />
                <span className="checkbox-text">Subscribe</span>
              </label>
            </a>
        </div>
        <div className="category-image">
          <img src="/images/Email_Sample_Reddit.png" alt="Sample Reddit Stocks Summary From Newsletter" onClick={() => openModal('reddit')} />
        </div>
      </div>
      {selectedCategory === 'reddit' && isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal">
            {/* Display the modal content for the selected category */}
            <img
              src="/images/Email_Sample_Reddit.png"
              alt="Sample Reddit Stocks Summary From Newsletter"
              className="modal-image"
            />
          </div>
        </div>
      )}
      <div className="category">
        <div className="category-content">
            <a className="categoryLink" href="/Stock-Market" /* Add the link to your homepage here */>
              <h2>Jim Cramer Report</h2>
              <p>
                Stay informed about the latest developments in the stock market with our daily updates.
                Get insights, news, and analysis to make informed investment decisions.
              </p>
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  className="larger-checkbox"
                  checked={selectedCategories.stocks}
                  onChange={() => toggleCategorySelection('stocks')}
                />
                <span className="checkbox-text">Subscribe</span>
              </label>
            </a>
        </div>
        <div className="category-image">
          <img src="/images/Email_Sample.png" alt="Sample Stock Summary From Newsletter" onClick={() => openModal('stocks')} />
        </div>
      </div>
      {selectedCategory === 'stocks' && isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal">
            {/* Display the modal content for the selected category */}
            <img
              src="/images/Email_Sample.png"
              alt="Sample Stock Summary From Newsletter"
              className="modal-image"
            />
          </div>
        </div>
      )}


      <div className="category">
        <div className="category-content">
          <a className="categoryLink" href="/Housing-Market" /* Add the link to your homepage here */>
            <h2>Housing Market Updates</h2>
            <p>
              Explore the current trends in the housing market. Whether you're a buyer, seller, or
              just interested in real estate, our daily updates have you covered.
            </p>
            <label className="checkbox-label">
              <input
                type="checkbox"
                className="larger-checkbox"
                checked={selectedCategories.housing}
                onChange={() => toggleCategorySelection('housing')}
              />
              <span className="checkbox-text">Subscribe</span>
            </label>
          </a>
        </div>
        <div className="category-image">
          <img src="/images/Email_Sample_Housing.png" alt="Sample Housing Email Image" onClick={() => openModal('housing')} />
        </div>
      </div>
      {selectedCategory === 'housing' && isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal">
            {/* Display the modal content for the selected category */}
            <img
              src="/images/Email_Sample_Housing.png"
              alt="Sample Housing Email Image"
              className="modal-image"
            />
          </div>
        </div>
      )}


      <div className="category">
        <div className="category-content">
          <a className="categoryLink" href="/Bonds-Market" /* Add the link to your homepage here */>
            <h2>Bonds Market Updates</h2>
            <p>
                  Stay informed about the dynamic world of bonds and financial markets. Our daily updates provide insights into the latest developments, trends, and key information in the bond market.
            </p>
            <label className="checkbox-label">
              <input
                type="checkbox"
                className="larger-checkbox"
                checked={selectedCategories.bonds}
                onChange={() => toggleCategorySelection('bonds')}
              />
              <span className="checkbox-text">Subscribe</span>
            </label>
          </a>
        </div>
        <div className="category-image">
          <img src="/images/Email_Sample_Bonds.png" alt="Sample Bonds Email Image" onClick={() => openModal('bonds')} />
        </div>
      </div>
      {selectedCategory === 'bonds' && isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal">
            {/* Display the modal content for the selected category */}
            <img
              src="/images/Email_Sample_Bonds.png"
              alt="Sample Bond Email Image"
              className="modal-image"
            />
          </div>
        </div>
      )}

      {subscribed ? (
          <p>Thank you for subscribing! Check your email for a welcome letter.</p>
        ) : (
          <form className="subscription-form"  onSubmit={handleSubscribe}>
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={handleEmailChange}
              ref={inputRef}
              onInvalid={(e) => {
                  e.preventDefault(); // Prevent the default browser validation message
                  setErrorMessage(e.target.validationMessage);
              }}
            />
            <button className="subscribe-button">
              {subscribed ? 'Subscribed' : 'Subscribe'}
            </button>
          </form>
      )}
      {errorMessage && (
        <div className="error-message-container">
          <p className="error-message">{errorMessage}</p>
        </div>
      )}
      <div className="category">
        <div className="category-content">
          <h2>Future Categories</h2>
          <p>
            We are continuously working to expand our newsletter content. If you have specific
            categories you'd like us to cover, please let us know. Your feedback is valuable to us!
          </p>

        </div>
        <div className="category-image">
          <a href="/contact" className="contact-btn">Contact Us</a>
        </div>
      </div>
    </div>
  );
}

export default Categories;
