import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, Link, useLocation } from 'react-router-dom';
import './Article.css'; // Import your CSS file for styling
import { collection, getDocs, doc, setDoc, updateDoc, query, getDoc, where } from "firebase/firestore";

import { db } from './firebase'; // Adjust the path to firebase.js as needed
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faCopy } from '@fortawesome/free-solid-svg-icons';



function Article() {
  const { articleTitle } = useParams(); // Change articleId to articleTitle
  const location = useLocation();
  const [post, setPost] = useState(null);
  const [copySuccess, setCopySuccess] = useState('');
  const [copyError, setCopyError] = useState('');
  const [author, setAuthor] = useState('');


  const [email, setEmail] = useState('');
  const [subscribed, setSubscribed] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubscribe = async (e) => {
      e.preventDefault(); // Prevent the default form submission behavior
      try {

        const lowercaseEmail = email.toLowerCase();
        console.log("Lowercased email:", lowercaseEmail);


        // Get the current count of documents in the "subscribers" collection
        const subscribersCollectionRef = collection(db, "subscribers");
        console.log("Subscribers collection reference:", subscribersCollectionRef);

        const querySnapshot = await getDocs(subscribersCollectionRef);
        const currentCount = querySnapshot.docs.length;
        console.log("Current document count:", currentCount);

        // Generate the next document ID
        const nextDocumentId = `subscriber_${currentCount + 1}`;
        console.log("Next document ID:", nextDocumentId);

        // Find the subscriber document with the matching email
        let subscriberDocRef;
        querySnapshot.forEach((doc) => {
          const subscriberData = doc.data();
          console.log("Checking document with email:", subscriberData.email);
          if (subscriberData.email === lowercaseEmail) {
            subscriberDocRef = doc.ref;
            console.log("Matching document found:", subscriberData.email);
          }
        });

        if (subscriberDocRef) {
          // Subscriber with the email already exists, update subscriptions
          console.log("Subscriber with email already exists. Updating subscriptions.");

          const subscriberData = await getDoc(subscriberDocRef);
          if (!subscriberData.data().referralLink) {
            // Generate a referral link and update the subscriber document
            const referralLink = generateReferralLink();
            await updateDoc(subscriberDocRef, { referralLink: referralLink });
          }

          await updateDoc(subscriberDocRef, {
            "subscriptions.stocks": true,
            "subscriptions.housing": true,
            "subscriptions.bonds": true,
            "subscriptions.analystStockPicks": true,
            "subscriptions.overallMarketTrends": true,
            "subscriptions.": true,
            "isSubscribed": true,
            "Premium": false
          });

          console.log("Subscriber document updated with email:", lowercaseEmail);
          setSubscribed(true);
          await sendWelcomeEmail(email);
          window.location.href = '/confirmation';
        } else {
          // Subscriber with the email does not exist, create a new document
          console.log("Subscriber with email does not exist. Creating a new document.");
          const initialSubscriptions = {
            stocks: true,
            housing: true,
            bonds: true,
            redditStocks: true,
            overallMarketTrends: true,
            analystStockPicks: true,
          };

          const newSubscriberDocRef = doc(db, "subscribers", nextDocumentId);
          console.log("New subscriber document reference:", newSubscriberDocRef);

          const referralLink = generateReferralLink();
          console.log("Referral Link for user: " + referralLink);

          await setDoc(newSubscriberDocRef, {
            email: lowercaseEmail,
            isSubscribed: true,
            subscribedOn: new Date(),
            subscriptions: initialSubscriptions,
            referralLink: referralLink,
            referralCount: 0,
            Premium: false
          });

          console.log("New subscriber document created with email:", lowercaseEmail);
          setSubscribed(true);
          await sendWelcomeEmail(email);
          window.location.href = '/confirmation';
        }
      } catch (error) {
        console.error("Error adding/updating subscriber:", error);
      }
    };

  const generateReferralLink = () => {
      // Generate a unique identifier, e.g., a random string or a unique ID
      const uniqueIdentifier = generateGuid(); // Implement this function accordingly

      // Construct the referral link based on your desired format
      const referralLink = `https://roborecap.com/signup?ref=${uniqueIdentifier}`;

      return referralLink;
    };
    const generateGuid = () => {
      // Generate a random part of the GUID
      const s4 = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);

      // Combine the random parts to create a complete GUID
      return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
    };
  const sendWelcomeEmail = async (email) => {
     try {
       console.log("Sending welcome email.");
       // Make an HTTP POST request to the Flask endpoint
       //const response = await axios.post('http://localhost:5000/api/send-welcome-email', {
       const response = await axios.post('https://tylerkarol.pythonanywhere.com/api/send-welcome-email', {
         email: email
       });

       if (response.data.message === 'Welcome email sent successfully') {
         console.log("Welcome email sent successfully");
       } else {
         console.error("Failed to send welcome email");
       }
     } catch (error) {
       console.error("Error sending welcome email:", error);
     }
   };

  useEffect(() => {


    const fetchArticleId = async () => {
      try {
        const articlesCollectionRef = collection(db, 'articles');
        console.log("article title: " + articleTitle);
        const querySnapshot = await getDocs(query(articlesCollectionRef, where('title', '==', articleTitle)));
        if (!querySnapshot.empty) {
          // Retrieve the article ID from the first matching document
          const articleId = querySnapshot.docs[0].data().articleId;
          console.log("articleID: " + articleId);

          // Fetch the article content using the retrieved articleId
          axios
            .get(`https://public-api.wordpress.com/rest/v1/sites/roborecap.wordpress.com/posts/${articleId}`)
            .then((res) => {
              setPost(res.data);
              const authorInfo = res.data.author;
              setAuthor(authorInfo);
            })
            .catch((error) => console.log(error));
        } else {
          console.log('Article title not found in Firestore.');
        }
      } catch (error) {
        console.error('Error fetching article ID:', error);
      }
    };



    if (articleTitle) {
      fetchArticleId();
    }
  }, [articleTitle]);



  if (!post) {
    return <div>Loading...</div>;
  }

  const { title, content, featured_image, date } = post;

  // Format the date to a human-readable format (e.g., "October 23, 2023")
  const formattedDate = new Date(date).toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  const decodeHtmlEntities = (html) => {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  };


    const copyArticleLink = () => {
      const url = window.location.href;

      try {
        // Create a text area element to hold the URL
        const textArea = document.createElement('textarea');
        textArea.value = url;
        document.body.appendChild(textArea);
        textArea.select();

        // Attempt to copy the URL to the clipboard
        const successful = document.execCommand('copy');
        document.body.removeChild(textArea);

        if (successful) {
          setCopySuccess('Link copied to clipboard!');
          setCopyError('');
        } else {
          setCopySuccess('');
          setCopyError('Copy failed. Please select the link and copy it manually.');
        }
      } catch (error) {
        setCopySuccess('');
        setCopyError('Copy failed. Please select the link and copy it manually.');
      }
    };


  return (
    <div className="article">
      {featured_image && (
        <img
          className="img-responsive webpic"
          alt="article header image"
          src={featured_image}
        />
      )}
      <h1 className="text-center article-title">{decodeHtmlEntities(title)}</h1>
      <p className="author-on-post">Author: {author.name}</p>


      <p className="publication-date-on-post">Published on {formattedDate}</p>
      <div className="share-links">
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`}
          target="_blank"
          className="share-link"
        >
          <FontAwesomeIcon icon={faFacebook} /> Share on Facebook
        </a>
        <a
          href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}`}
          target="_blank"
          className="share-link"
        >
          <FontAwesomeIcon icon={faTwitter} /> Share on Twitter
        </a>
        <a
          href="javascript:void(0)"
          onClick={copyArticleLink}
          className="share-link"
        >
          <FontAwesomeIcon icon={faCopy} /> Copy Link
        </a>

      </div>

      <div className="article-content" dangerouslySetInnerHTML={{ __html: content }} />
      <div className="buttonsOnArticle">
        {subscribed ? (
          <p>Thank you for subscribing! Check your email for a welcome letter.</p>
        ) : (
          <div className="subscription-form">
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={handleEmailChange}
            />
            <button onClick={handleSubscribe}>Subscribe</button>
          </div>
        )}
        <Link to="/blog">
          <button className="returnToBlogButton">Return to Blog</button>
        </Link>
      </div>
    </div>
  );
}

export default Article;
