import React, { useState } from 'react';
import './ConfirmationPage.css'; // Import the CSS
import { updateDoc, doc, getDoc, increment } from "firebase/firestore"; // Import Firebase
import { db } from './firebase'; // Adjust the path to firebase.js as needed

function ConfirmationPage() {
    const [selectedOption, setSelectedOption] = useState(""); // State to store the selected option
    const [submitted, setSubmitted] = useState(false);

    const handleFormSubmit = async () => {
        if (!selectedOption) {
            alert('Please select how you heard about us before submitting.');
            return;
        }

        try {
            // Get a reference to the selected option document
            const userOriginRef = doc(db, "userOrigin", selectedOption);
            const userOriginDoc = await getDoc(userOriginRef);

            if (userOriginDoc.exists()) {
                // Update the "people" field by incrementing it by 1
                await updateDoc(userOriginRef, { people: increment(1) });
                console.log(`Updated people count for ${selectedOption}`);
            }
        } catch (error) {
            console.log("Error updating userOrigin document:", error);
        }

        // Continue with the rest of your form submission logic
        // ...

        // Clear the selected option
        setSelectedOption("");
        setSubmitted(true);
    };

    return (
        <div className="confirmation-page">
            <div className="confirmation-div">
                <h1>Thank You for Subscribing!</h1>
                <p>We promise you won't regret it.</p>
                <p>Reply "hi" to our welcome email to ensure our newsletter gets delivered to the right spot.</p>
                <p>Be sure to check out our blog and other markets we cover for a completely personalized newsletter.</p>
                {submitted ? ( // Conditionally render based on the "submitted" state
                    <div>
                        <h2>Thanks for your help! Enjoy all we have to offer below.</h2>
                    </div>
                ) : (
                    <form className="how-did-you-hear-form">
                        <h5>How did you hear about us?</h5>
                        <div className="how-did-you-hear-form-div">
                            <label>
                                <input
                                    type="radio"
                                    name="howDidYouHear"
                                    value="Online Ad"
                                    checked={selectedOption === "Online Ad"}
                                    onChange={() => setSelectedOption("Online Ad")}
                                />
                                Online Ad
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="howDidYouHear"
                                    value="Social Media"
                                    checked={selectedOption === "Social Media"}
                                    onChange={() => setSelectedOption("Social Media")}
                                />
                                Social Media
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="howDidYouHear"
                                    value="Friend"
                                    checked={selectedOption === "Friend"}
                                    onChange={() => setSelectedOption("Friend")}
                                />
                                Friend
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="howDidYouHear"
                                    value="Google"
                                    checked={selectedOption === "Google"}
                                    onChange={() => setSelectedOption("Google")}
                                />
                                Google
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="howDidYouHear"
                                    value="The News"
                                    checked={selectedOption === "The News"}
                                    onChange={() => setSelectedOption("The News")}
                                />
                                Fox 4 News
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="howDidYouHear"
                                    value="Other"
                                    checked={selectedOption === "Other"}
                                    onChange={() => setSelectedOption("Other")}
                                />
                                Other
                            </label>
                            <button type="button" className={`buttonSubmit ${!selectedOption ? "disabled-button" : ""}`} onClick={handleFormSubmit} disabled={!selectedOption}>
                                Submit
                            </button>
                        </div>
                    </form>
                )}
                <div className="buttonsOnConfirmation">
                    <a href="/blog" className="button">Visit Our Blog</a>
                    <a href="/categories" className="button">All The Markets We Cover</a>
                    <a href="/" className="button">Return to Home</a>
                </div>
            </div>
        </div>
    );
}

export default ConfirmationPage;
