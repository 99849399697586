import React, { useEffect } from 'react';
import { query, where, updateDoc, getDocs, collection } from 'firebase/firestore';
import { db } from './firebase'; // Adjust the path to firebase.js as needed
import './PremiumUnsubscribe.css'; // Import appropriate CSS

function PremiumUnsubscribe() {
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const userEmail = urlParams.get('email');

        if (userEmail) {
            updatePremiumStatus(userEmail);
        }
    }, []);

    const updatePremiumStatus = async (userEmail) => {
        try {
            const lowerCaseEmail = userEmail.toLowerCase(); // Convert email to lowercase
            const subscribersRef = collection(db, 'subscribers');
            const q = query(subscribersRef, where('email', '==', lowerCaseEmail));
            const querySnapshot = await getDocs(q);

            for (const doc of querySnapshot.docs) {
                try {
                    await updateDoc(doc.ref, {
                        Premium: false, // Update Premium status to false
                    });
                } catch (error) {
                    console.error('Error updating premium field:', error);
                }
            }
        } catch (error) {
            console.error('Error fetching documents:', error);
        }
    };


    return (
        <div className="unsubscription-page">
            <h1>Unsubscription Successful</h1>
            <p>We're sorry to see you go! If you ever decide to return, we'll be here to welcome you back.</p>
            <a href="/" className="button">Return to Home</a>
        </div>
    );
}

export default PremiumUnsubscribe;
