import React from 'react';
import './OverallMarketTrends.css'; // Import the CSS file for styling

function OverallMarketTrends() {
  return (
    <div className="overall-market-trends">
      <h1>Overall Market Trends & News</h1>
      <p>
        Stay informed about the latest trends and news in the overall financial market.
        Get insights, updates, and analysis on key market indicators and developments.
      </p>

      <h2>What You'll Receive:</h2>
      <ul>
        <li>Market Overview and Analysis</li>
        <li>Key Trends and Economic Indicators</li>
        <li>News and Events Impacting Financial Markets</li>
      </ul>

      <h2>Example Summary:</h2>
      <div className="example-email">
        <div className="email-content">
          <h4>Overall Market Trends & News:</h4>

          <h5>Key Points:</h5>
          <ul>
            <li>[Key Point 1]: [Description of the first key point in the financial market trend.]</li>
            <li>[Key Point 2]: [Description of the second key point in the financial market trend.]</li>
            <li>[Key Point 3]: [Description of the third key point in the financial market trend.]</li>
            <li>[Key Point 4]: [Description of the fourth key point in the financial market trend.]</li>
            <li>[Key Point 5]: [Description of the fifth key point in the financial market trend.]</li>
          </ul>
        </div>
      </div>

      {/* Add a placeholder for the example summary picture */}
      <div className="example-email-picture">
        <img src="/images/Email_Sample_Overall_Market.png" alt="Sample Financial Market Trends Summary" />
      </div>
    </div>
  );
}

export default OverallMarketTrends;
