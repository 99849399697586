import React from 'react';
import { NavLink } from 'react-router-dom'; // Import NavLink for routing
import './Footer.css'; // Import your CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-logo">
          <a href="/" /* Add the link to your homepage here */>
            <img src="/images/Logo_no_words_black.png" alt="RoboRecap Logo" />
          </a>
        </div>
        <div className="footer-links">
          <ul>
            <li><NavLink to="/" className="mainFooterLinkText">Home</NavLink></li>
            <li><NavLink to="/blog" className="mainFooterLinkText">Blog</NavLink></li>
            <li className="mainFooterLink">
              <NavLink to="/categories" className="mainFooterLinkText">Categories</NavLink>
            </li>
            <li><NavLink to="/about" className="mainFooterLinkText">About</NavLink></li>
            <li><NavLink to="/contact" className="mainFooterLinkText">Contact</NavLink></li>
          </ul>
        </div>
      </div>
      <div className="footer-social">
        <p className="footer-copyright">&copy; {new Date().getFullYear()} RoboRecap LLC rights reserved.</p>
        <div className="share-links-footer">
            <a
              href={`https://www.facebook.com/roborecapreport/`}
              target="_blank"
              className="share-link-footer"
            >
              <FontAwesomeIcon icon={faFacebook} className="footer-icon"/>
            </a>
            <a
              href={`https://www.instagram.com/roborecapreport/`}
              target="_blank"
              className="share-link-footer"
            >
              <FontAwesomeIcon icon={faInstagram} className="footer-icon-insta"/>
            </a>
            <a
              href={`https://twitter.com/RoboRecapreport`}
              target="_blank"
              className="share-link-footer"
            >
              <FontAwesomeIcon icon={faTwitter} className="footer-icon"/>
            </a>
        </div>

      </div>
    </footer>
  );
}

export default Footer;

