import React, { useState, useEffect, useRef } from 'react';
import { db } from './firebase'; // Adjust the path to firebase.js as needed
import './Home.css'; // Import the CSS file
import { collection, getDocs, doc, setDoc, updateDoc, getDoc, query, orderBy } from "firebase/firestore";
import axios from 'axios'; // Import axios for making HTTP requests
import { Link } from 'react-router-dom';
import ArticlePreview from './ArticlePreview';
import NewsletterArchivePreview from './NewsletterArchivePreview';


function Home() {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [latestBlogs, setLatestBlogs] = useState([]);
  const [allBlogs, setAllBlogs] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const inputRef = useRef(null); // Create a ref for the input element
  const [errorMessageVisible, setErrorMessageVisible] = useState(false);
  const [archivedNewsletters, setArchivedNewsletters] = useState([]);

  
  // Function to open the modal
  const openModal = () => {
    console.log("modal open");
    setIsModalOpen(true);
  };
  
  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const [email, setEmail] = useState('');
  const [subscribed, setSubscribed] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubscribe = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    if (!email || email.indexOf('@') === -1) {
              setErrorMessageVisible(true); // Show the error message container
              return;
            }
    try {

      const lowercaseEmail = email.toLowerCase();
      console.log("Lowercased email:", lowercaseEmail);


      // Get the current count of documents in the "subscribers" collection
      const subscribersCollectionRef = collection(db, "subscribers");
      console.log("Subscribers collection reference:", subscribersCollectionRef);

      const querySnapshot = await getDocs(subscribersCollectionRef);
      const currentCount = querySnapshot.docs.length;
      console.log("Current document count:", currentCount);

      // Generate the next document ID
      const nextDocumentId = `subscriber_${currentCount + 1}`;
      console.log("Next document ID:", nextDocumentId);

      // Find the subscriber document with the matching email
      let subscriberDocRef;
      querySnapshot.forEach((doc) => {
        const subscriberData = doc.data();
        console.log("Checking document with email:", subscriberData.email);
        if (subscriberData.email === lowercaseEmail) {
          subscriberDocRef = doc.ref;
          console.log("Matching document found:", subscriberData.email);
        }
      });

      if (subscriberDocRef) {
        // Subscriber with the email already exists, update subscriptions
        console.log("Subscriber with email already exists. Updating subscriptions.");

        const subscriberData = await getDoc(subscriberDocRef);
        if (!subscriberData.data().referralLink) {
          // Generate a referral link and update the subscriber document
          const referralLink = generateReferralLink();
          await updateDoc(subscriberDocRef, { referralLink: referralLink });
        }

        await updateDoc(subscriberDocRef, {
          "subscriptions.stocks": true,
          "subscriptions.housing": true,
          "subscriptions.bonds": true,
          "subscriptions.analystStockPicks": true,
          "subscriptions.overallMarketTrends": true,
          "subscriptions.": true,
          "isSubscribed": true,
          "Premium": false
        });

        console.log("Subscriber document updated with email:", lowercaseEmail);
        setSubscribed(true);
        await sendWelcomeEmail(email);
        window.location.href = '/confirmation';
      } else {
        // Subscriber with the email does not exist, create a new document
        console.log("Subscriber with email does not exist. Creating a new document.");
        const initialSubscriptions = {
          stocks: true,
          housing: true,
          bonds: true,
          redditStocks: true,
          overallMarketTrends: true,
          analystStockPicks: true,
        };

        const newSubscriberDocRef = doc(db, "subscribers", nextDocumentId);
        console.log("New subscriber document reference:", newSubscriberDocRef);

        const referralLink = generateReferralLink();
        console.log("Referral Link for user: " + referralLink);

        await setDoc(newSubscriberDocRef, {
          email: lowercaseEmail,
          isSubscribed: true,
          subscribedOn: new Date(),
          subscriptions: initialSubscriptions,
          referralLink: referralLink,
          referralCount: 0,
          Premium: false
        });

        console.log("New subscriber document created with email:", lowercaseEmail);
        setSubscribed(true);
        await sendWelcomeEmail(email);
        window.location.href = '/confirmation';
      }
    } catch (error) {
      console.error("Error adding/updating subscriber:", error);
    }
  };

  const generateReferralLink = () => {
    // Generate a unique identifier, e.g., a random string or a unique ID
    const uniqueIdentifier = generateGuid(); // Implement this function accordingly

    // Construct the referral link based on your desired format
    const referralLink = `https://roborecap.com/signup?ref=${uniqueIdentifier}`;

    return referralLink;
  };
  const generateGuid = () => {
    // Generate a random part of the GUID
    const s4 = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);

    // Combine the random parts to create a complete GUID
    return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
  };



  const sendWelcomeEmail = async (email) => {
    try {
      console.log("Sending welcome email.");
      // Make an HTTP POST request to the Flask endpoint
      //const response = await axios.post('http://localhost:5000/api/send-welcome-email', {
      const response = await axios.post('https://tylerkarol.pythonanywhere.com/api/send-welcome-email', {
        email: email
      });

      if (response.data.message === 'Welcome email sent successfully') {
        console.log("Welcome email sent successfully");
      } else {
        console.error("Failed to send welcome email");
      }
    } catch (error) {
      console.error("Error sending welcome email:", error);
    }
  };
  useEffect(() => {
    const fetchLatestBlogs = async () => {
      try {
        const response = await axios.get(
          'https://public-api.wordpress.com/rest/v1.1/sites/roborecap.wordpress.com/posts'
        );
        const allPosts = response.data.posts;
        setAllBlogs(allPosts);
        const posts = response.data.posts.slice(0, 3); // Get the latest three posts
        setLatestBlogs(posts);
      } catch (error) {
        console.error('Error fetching latest blogs:', error);
      }
    };

    fetchLatestBlogs();
  }, []);
  useEffect(() => {
    // Add a click event listener to the window

    // Add a focus event listener to the input element
    if (inputRef.current) {
      inputRef.current.addEventListener('focus', handleInputFocus);
    }
  }, []);
  useEffect(() => {
      const fetchArchivedNewsletters = async () => {
        try {
          const newslettersCollectionRef = collection(db, 'previousNewsletters');
          const q = query(newslettersCollectionRef, orderBy('published', 'desc'));
          const querySnapshot = await getDocs(q);
          const newsletters = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
          setArchivedNewsletters(newsletters.slice(0,3));
        } catch (error) {
          console.error('Error fetching archived newsletters:', error);
        }
      };

      fetchArchivedNewsletters();
    }, []);

    const handleInputFocus = () => {
      // Hide the error message when the input is focused
      setErrorMessage('');
    };

  return (
    <div>
        <div className="main-section-home">
            <div className="cta-section">

              <h2>Welcome to <span className="roboTitle">RoboRecap</span></h2>
              {/* Add any other introduction content here */}
              <h1>Your AI-Generated Financial Newsletter</h1>
              <h4>Stay updated with the latest financial insights and stock recommendations.</h4>
              {subscribed ? (
                <p>Thank you for subscribing! Check your email for a welcome letter.</p>
              ) : (
                <>
                  <form className="subscription-form" onSubmit={handleSubscribe}>
                    <input
                      type="email"
                      placeholder="Enter email"
                      value={email}
                      onChange={handleEmailChange}
                      ref={inputRef}
                      onInvalid={(e) => {
                        e.preventDefault();
                        setErrorMessage(e.target.validationMessage);
                        setErrorMessageVisible(true);
                      }}
                    />
                    <button>Get Started Now ⇨</button>
                  </form>
                  <div className="subscriber-container">
                    <div className="subscriber-images">
                      <img className="subscriber-pic-cta" src="/images/SocialMedia/Chris.jpg" alt="Investor" />
                      <img className="subscriber-pic-cta" src="/images/SocialMedia/Neelie.jpeg" alt="Investor" />
                      <img className="subscriber-pic-cta" src="/images/SocialMedia/Charlie.jpeg" alt="Investor" />
                      <img className="subscriber-pic-cta" src="/images/SocialMedia/Colton.jpg" alt="Investor" />
                    </div>
                    <p>Join our growing family of smart investors for exclusive updates.</p>
                  </div>


                </>
              )}

              {errorMessage && (
                  <div className="error-message-container">
                    <p className="error-message">{errorMessage}</p>
                  </div>
                )}
              <div className="testimonials-homePage">
                  <p className="testimonials-text"><span className="stars-testimonial">★★★★★</span> "RoboRecap has made me a smarter investor. It's like having a financial advisor in your inbox every day." - Mark T.</p>
                  <p className="testimonials-text"><span className="stars-testimonial">★★★★★</span> "I've subscribed to many newsletters, but RoboRecap's advice is gold. My portfolio thanks you!" - Lisa R. </p>

              </div>
              <h5> ** Featured on <a href="https://www.fox4now.com/news/local-news/a-i-in-swfl-how-artificial-intelligence-is-changing-the-job-market">Fox 4!</a> **</h5>

            </div>
            {/*
            <div className="home-3d-container">
              <Robot />
            </div>
            */}
        </div>
      <div className="additional-content">
        {/* Sample Content */}
        <section className="sample-content">
          <div className="sample-content-container">
            <div className="sample-text">
              <h1>What is <span className="roboTitle">RoboRecap</span>?</h1>
              <h2>Your New, Personalized AI Newsletter</h2>
              <p>RoboRecap is an innovative AI Newsletter generator designed to deliver high-value summaries of market experts' insights. It provides essential financial information, stock recommendations, and more in a concise and easily digestible format.</p>
            </div>
            <div className="sample-image">
              <img src="/images/SocialMedia/SampleStockOnPC.png" alt="Sample Stock Summary From Newsletter" onClick={openModal} />
            </div>
          </div>
        </section>
        {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal">
            {/* Display the same image at a larger scale */}
            <img
              src="/images/SocialMedia/SampleStockSummary.png"
              alt="Sample Stock Summary From Newsletter Email"
              className="modal-image"
            />
          </div>
        </div>
        )}

        <section className="news-feature">
          <div className="news-feature-text">
            <h1>What makes us different?
            </h1>
          </div>
          <div className="news-feature-video">
            <video controls poster="/images/SocialMedia/InterviewThumbnail.png">
              <source src="/images/SocialMedia/StoryClip.mp4" type="video/mp4" />
              <source src="/images/SocialMedia/StoryClip.webm" type="video/webm" />
              <source src="/images/SocialMedia/StoryClip.mov" type="video/quicktime" />
              Fox 4 Interview on Robo Recap Clip
            </video>

          </div>
        </section>




        {/* Newsletter Benefits */}
        <section className="newsletter-benefits">
          <div className="benefits-list">
            <div className="benefit-item">
              <img src="/images/quick.png" alt="Benefit 1: Quick" />
              <p>Stay up-to-date with key insights in less than 5 minutes.</p>
            </div>
            <div className="benefit-item">
              <img src="/images/artificial-intelligence.png" alt="Benefit 2: AI Generated" />
              <p>Access AI-generated summaries from market experts.</p>
            </div>
            <div className="benefit-item">
              <img src="/images/24-hours.png" alt="Benefit 3: Time" />
              <p>Never miss out on important discussions from market leading analysts.</p>
            </div>
          </div>
          {/* Add more benefits content here */}
        </section>


        <div className="previous-emails-section">
          <h1>Check out RoboRecap's Previous Newsletters</h1>
          <div className="home-archives-list">
            {archivedNewsletters.map((newsletter, index) => (
              <NewsletterArchivePreview key={newsletter.id} newsletter={newsletter} index={index} />
            ))}
          </div>
          <a href="/Newsletter-Archives">
            <button className="btn btn-primary">Explore Newsletters</button>
          </a>
        </div>

        <section className="blog-section">
          <h1>Explore The RoboRecap Blog</h1>
          <p>
            Visit our blog for articles that encompass a variety of subjects. Delve deeper into the topics
            that resonate with you, including AI newsletter generation and more.
          </p>
          <div className="latest-blogs">
            {latestBlogs.map((blog) => (
              <ArticlePreview key={blog.title} post={blog} />
            ))}
          </div>
          <h3>Explore our other blogs</h3>
          <div className="other-blogs-home">
            {allBlogs.map((blog) => (
              <ArticlePreview key={blog.title} post={blog} />
            ))}
          </div>
          <a href="/blog">
            <button className="btn btn-primary">Read Our Blog</button>
          </a>
          {/*
          <a href="https://www.buymeacoffee.com/tylerkarol">
             <img className="buyMeACoffee" src="/images/buymeacoffee.png" alt="Buy me a coffee image" />
          </a>
          */}
        </section>
      </div>
    </div>
  );
}

export default Home;
