import React from 'react';
import './About.css'; // Import your CSS file for styling
import TradingViewWidget from './TradingViewWidget';

function About() {
  return (
    <div className="about">
      <h1>About RoboRecap</h1>
      <div className="about-content">
        <p>
          Picture this: It was a time when the world was buzzing with innovation, and we, a group of passionate individuals, were on a quest. Our shared dream was simple yet profound—to never miss a single word, a single insight, or a single moment of wisdom shared by financial analysts and experts. This is the story of how RoboRecap was born.
        </p>

        <p>
          The journey began with a realization that AI had the power to unlock a world of knowledge. It all started when we found ourselves missing out on valuable discussions and opinions shared by analysts live, especially in the dynamic world of finance. We felt the pang of not being in the loop, not having access to the latest trends, and it became clear that we needed a solution.
        </p>

        <p>
          With a shared passion for staying informed and a deep love for finance, we decided to embark on a thrilling adventure. Leveraging our skills as software developers, we set out to create a platform that would empower us and others to bridge that knowledge gap. Our focus became crystal clear—to harness the power of AI to summarize analyst discussions, and deliver this invaluable information right to your inbox.
        </p>

        <p>
          Our mission is to make sure you never miss a beat in the financial world or any other domain you're passionate about. We're committed to delivering the insights you crave, empowering you with the latest trends and updates. As you become a part of our journey, you're not just joining a platform; you're becoming part of a story where missing out is a thing of the past. Welcome to our world, where staying informed is not just a dream; it's a reality.
        </p>
      </div>
    </div>
  );
}

export default About;
