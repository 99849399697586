import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './NewsletterArchivePreview.css';

function NewsletterArchivePreview({ newsletter, index }) {
  const [showFullContent, setShowFullContent] = useState(false);

  const toggleContent = () => {
    setShowFullContent(!showFullContent);
  };

  return (
    <div className="newsletter-preview" key={index}>
      <p>Published on {new Date(newsletter.published.toDate()).toLocaleDateString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' })}</p>
      {showFullContent ? (
        <div dangerouslySetInnerHTML={{ __html: newsletter.content }} />
      ) : (
        <div
          dangerouslySetInnerHTML={{
            __html: newsletter.content.substring(0, 1200), // Display a portion of the content
          }}
        />
      )}
      {newsletter.content.length > 300 && (
        <button className="newsletter-preview-read-more-button" onClick={toggleContent}>
          {showFullContent ? 'Read Less' : 'Read More'}
        </button>
      )}
      <Link to={`/Newsletter-Archives/${newsletter.id}`}>
          <button className="newsletter-preview-view-full-button">View Full Newsletter</button>
      </Link>
    </div>
  );
}

export default NewsletterArchivePreview;
