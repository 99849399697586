import React from 'react';
import './StockMarket.css'; // Import the CSS file for styling

function StockMarket() {
  return (
    <div className="stock-market">
      <h1>Stock Market Updates</h1>
      <p>
        Stay informed about the latest developments in the stock market with our daily updates.
        Get insights, news, and analysis to make informed investment decisions.
      </p>

      <h2>What You'll Receive:</h2>
      <ul>
        <li>An Overview of the Current Stock Market</li>
        <li>Key Points and Market Analysis</li>
        <li>Specific Stock Recommendations from Expert Jim Cramer</li>
      </ul>

      <h2>Example Email:</h2>
      <div className="example-email">
        <div className="email-content">
          <h4>Summary Title of Main Topic:</h4>
          <p>Introduction: Brief introduction regarding latest on Jim Cramer's Mad Money</p>

          <h5>Key Points:</h5>
          <ol>
            <li>[Current Market State (Dow, S&P, Nasdaq)]: [Include the current state of the Dow, S&P, and Nasdaq indices.]</li>
            <li>[Key Point 1]</li>
            <li>[Key Point 2]</li>
            <li>[Key Point 3]</li>
            <li>[Key Point 4]</li>
          </ol>
          <h5>Stock Recommendations:</h5>
          <ul>
            <li>Stock 1 (BUY/SELL/HOLD): Brief rationale for the recommendation.</li>
            <li>Stock 2 (BUY/SELL/HOLD): Brief rationale for the recommendation.</li>
            <li>Stock 3 (BUY/SELL/HOLD): Brief rationale for the recommendation.</li>
            <li>Stock 4 (BUY/SELL/HOLD): Brief rationale for the recommendation.</li>
          </ul>

          <h5>Bottom Line:</h5>
          <p>Provides a concluding statement or opinion based on the transcript's content and analysis. Note any significant insights or trends.</p>
        </div>
      </div>


      {/* Add a placeholder for the example email picture */}
      <div className="example-email-picture">
        <img src="/images/Email_Sample.png" alt="Sample Stock Summary From Newsletter" />
      </div>
    </div>
  );
}

export default StockMarket;
