import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobileScreen, setIsMobileScreen] = useState(false);

  useEffect(() => {
    const checkScreenWidth = () => {
      setIsMobileScreen(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    // Initial check
    checkScreenWidth();

    // Event listener for window resize
    window.addEventListener('resize', checkScreenWidth);

    // Cleanup
    return () => {
      window.removeEventListener('resize', checkScreenWidth);
    };
  }, []);

  const handleMobileMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  // Function to close the mobile menu when a NavLink is clicked
  const closeMobileMenu = () => {
    if (isMobileScreen) {
      setIsMobileMenuOpen(false);
    }
  };

  return (
      <nav>
        <div className="logo">
          <a href="/">
            <img src="/images/Logo_no_words_black.png" alt="RoboRecap Logo" />
          </a>
        </div>
        {isMobileScreen && (
          <div className={`menu-icon ${isMobileMenuOpen ? 'active' : ''}`} onClick={handleMobileMenuToggle}>
            &#9776; {/* Unicode hamburger icon */}
          </div>
        )}
        <ul className={`nav-links ${isMobileMenuOpen ? 'active' : ''}`}>
          <li className="mainNavLink">
            <a href="/Premium" className="mainNavLinkText" onClick={closeMobileMenu}>
              Premium
            </a>
          </li>
          <li className="mainNavLink">
            <a href="/blog" className="mainNavLinkText" onClick={closeMobileMenu}>
              Resources
            </a>
            <ul className="submenu">
              <li>
                <a href="/blog" onClick={closeMobileMenu}>
                  <span className="subLinkText">Blog</span>
                </a>
              </li>
              <li>
                <a href="/Newsletter-Archives" onClick={closeMobileMenu}>
                  <span className="subLinkText">Newsletter Archives</span>
                </a>
              </li>
              <li>
                <a href="/unsubscribe" onClick={closeMobileMenu}>
                  <span className="subLinkText">Unsubscribe</span>
                </a>
              </li>
            </ul>
          </li>
          <li className="mainNavLink">
            <a href="/categories" className="mainNavLinkText" onClick={closeMobileMenu}>
              Categories
            </a>
            <ul className="submenu">
              <li>
                <a href="/Analyst-Top-Stocks" onClick={closeMobileMenu}>
                  <span className="subLinkText">Stock Picks from Analysts</span>
                </a>
              </li>
              <li>
                <a href="/Overall-Market-Trends" onClick={closeMobileMenu}>
                  <span className="subLinkText">Overall Stock Market Trends & News</span>
                </a>
              </li>
              <li>
                <a href="/Reddit-Stocks" onClick={closeMobileMenu}>
                  <span className="subLinkText">Reddit Hot Stocks</span>
                </a>
              </li>
              <li>
                <a href="/Stock-Market" onClick={closeMobileMenu}>
                  <span className="subLinkText">Stock Market</span>
                </a>
              </li>
              <li>
                <a href="/Housing-Market" onClick={closeMobileMenu}>
                  <span className="subLinkText">Housing</span>
                </a>
              </li>
              <li>
                <a href="/Bonds-Market" onClick={closeMobileMenu}>
                  <span className="subLinkText">Bond Market</span>
                </a>
              </li>
              <li>
                <a href="/categories" onClick={closeMobileMenu}>
                  <span className="subLinkText">All Categories</span>
                </a>
              </li>
            </ul>
          </li>
          <li className="mainNavLink">
            <a href="/about" className="mainNavLinkText" onClick={closeMobileMenu}>
              About
            </a>
          </li>
          <li className="mainNavLink">
            <a href="/contact" className="mainNavLinkText" onClick={closeMobileMenu}>
              Contact/FAQ
            </a>
          </li>
        </ul>
      </nav>
  );
}

export default Navbar;
