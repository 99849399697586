import React from 'react';
import './AnalystStockPicks.css'; // Import the CSS file for styling

function AnalystStockPicks() {
  return (
    <div className="analyst-stock-picks">
      <h1>Analyst Stock Picks</h1>
      <p>
        Stay informed about the latest stock recommendations from top analysts.
        Get insights, news, and analysis on three carefully selected stocks updated every 24 hours.
      </p>

      <h2>What You'll Receive:</h2>
      <ul>
        <li>Three Stock Picks with Expert Explanations</li>
        <li>Picks from Leading Market Analysts</li>
        <li>Insights for Informed Investment Decisions</li>
      </ul>

      <h2>Example Email:</h2>
      <div className="example-email">
        <div className="email-content">
          <h4>Stock Picks from Analysts</h4>

          <h5>Key Points:</h5>
          <ul>
            <li>[Stock Pick 1]: [Brief explanation of the first stock pick.]</li>
            <li>[Stock Pick 2]: [Brief explanation of the second stock pick.]</li>
            <li>[Stock Pick 3]: [Brief explanation of the third stock pick.]</li>
          </ul>
        </div>
      </div>

      {/* Add a placeholder for the example email picture */}
      <div className="example-email-picture">
        <img src="/images/Email_Sample_Analyst_Stocks.png" alt="Sample Analyst Stock Picks Newsletter" />
      </div>
    </div>
  );
}

export default AnalystStockPicks;
