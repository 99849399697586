import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { db } from './firebase';
import { doc, getDoc } from 'firebase/firestore';
import './NewsletterArchive.css'; // Import your CSS file

const NewsletterArchive = () => {
  const { id } = useParams();
  const [newsletter, setNewsletter] = useState(null);

  useEffect(() => {
    const fetchNewsletter = async () => {
      try {
        // Reference to the specific newsletter in Firestore using the provided "id"
        const newsletterDocRef = doc(db, 'previousNewsletters', id);
        const docSnap = await getDoc(newsletterDocRef);

        if (docSnap.exists()) {
          setNewsletter(docSnap.data());
        } else {
          // Handle case where the newsletter doesn't exist
          console.error('Newsletter not found');
        }
      } catch (error) {
        console.error('Error fetching newsletter:', error);
      }
    };

    fetchNewsletter();
  }, [id]);

  if (!newsletter) {
    // Add loading or error handling here
    return <div>Loading...</div>;
  }

  return (
    <div className="full-newsletter-archive-container">
      <h1 className="full-newsletter-archive-title">{newsletter.title}</h1>
      <div
        className="full-newsletter-archive-content"
        dangerouslySetInnerHTML={{ __html: newsletter.content }}
      ></div>
      <Link to="/Newsletter-Archives">
        <button>Back to Archives</button>
      </Link>
    </div>
  );
};

export default NewsletterArchive;
